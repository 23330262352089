import { useMemo, useState } from 'react'
import { useApiListGroup, useApiListNodeGroup } from '../../api-new/state/content'
import { ModalDialog } from '../component/modal-dialog'
import { IconMinus, IconPlus } from '../icon/icon'
import { TextFilter } from './text-filter'
import { ButtonPrimary } from '../component/button'

export const PermissionNodeGroupForNodeModal = ({ target, nodeID, onClose, onClosed }) => {
  const [filter, updateFilter] = useState('')

  const apiListGroup = useApiListGroup()
  const apiListNodeGroup = useApiListNodeGroup()

  const filteredItems = useMemo(
    () => {
      let lowerFilter = filter.toLowerCase()
      return apiListGroup.items.filter(i => i.name.toLowerCase().includes(lowerFilter))
    },
    [apiListGroup.items, filter]
  )

  const handleOpen = () => {
    apiListGroup.get()
    apiListNodeGroup.get(`${nodeID}/node`)
  }
  const handleClose = () => onClose && onClose()

  const handleAdd = async groupID => {
    await apiListNodeGroup.post({ nodeID: nodeID, groupID: groupID })
    await apiListNodeGroup.get(`${nodeID}/node`)
  }

  const handleRemove = async groupID => {
    await apiListNodeGroup.delete(`${nodeID}/${groupID}`)
    await apiListNodeGroup.get(`${nodeID}/node`)
  }

  return (
    <ModalDialog target={target} header="Add Group" fullScreen onOpen={handleOpen} onClose={onClosed}>
      <div className="d-flex flex-column h-100">
        <div>
          <TextFilter value={filter} onChange={updateFilter} />
        </div>
        <div className="flex-fill overflow-auto p-2">
          {filteredItems.map((group, i) =>
            <SelectItem key={i} group={group} selectedNodeGroups={apiListNodeGroup.items} onAdd={handleAdd} onRemove={handleRemove} />
          )}
        </div>
        <div>
          <ButtonPrimary onClick={handleClose}>close</ButtonPrimary>
        </div>
      </div>
    </ModalDialog>
  )
}

const SelectItem = ({ group, selectedNodeGroups, onAdd, onRemove }) => {
  const isSelected = useMemo(
    () => {
      return selectedNodeGroups.findIndex(selectedNodeGroup => selectedNodeGroup.groupID === group.id) !== -1
    },
    [selectedNodeGroups, group]
  )

  const handleClick = () => {
    if (isSelected) {
      onRemove && onRemove(group.id)
    } else {
      onAdd && onAdd(group.id)
    }
  }

  return (
    <div className="p-1 border-bottom" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <span className="px-1">
        {isSelected ? <IconMinus /> : <IconPlus />}
      </span>
      <span>
        {group.name}
      </span>
    </div>
  )
}
