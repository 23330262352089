import { useState } from 'react'
import { TableEdit } from '../../../../shared/component/table-edit'
import { Form, FormDate, FormHiddenNumber, FormHiddenText, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'
import { useParamsInt } from '../../../../shared/hooks/location'
import moment from 'moment'
import { useAnalyticParentCourse } from './hook'

export const Period = ({ apiList, selectedPeriodID, onLoad }) => {
  const { nodeID } = useParamsInt()

  return (
    <TableEdit
      title="periods"
      columns={[
        { header: 'name', value: 'name', filter: true },
        { header: 'began at', value: row => moment(row.beganAt).format('MM-DD-YYYY') },
        { header: 'ended at', value: row => moment(row.endedAt).format('MM-DD-YYYY') },
        { header: 'description', value: 'description', filter: true }
      ]}
      apiList={apiList}
      formArgs={{ nodeID: nodeID }}
      sortBy="name"
      selectedFn={row => row.id === selectedPeriodID}
      FormAdd={FormEdit}
      FormEdit={FormEdit}
      autoAdd
      onLoad={onLoad}
      onAdd
      onEdit
      onDelete
      onRestore
      onPurge
    />
  )
}

const FormEdit = ({ row, nodeID, onSubmit, onClose }) => {
  const analyticParentCourse = useAnalyticParentCourse()

  const [min, updateMin] = useState()
  const [max, updateMax] = useState()

  const handleChangeBegin = value => updateMin(value)
  const handleChangeEnd = value => updateMax(value)

  return (
    <Form state={row} onSubmit={onSubmit}>
      <div className="row">
        <FormHiddenNumber name="analyticParentID" value={analyticParentCourse.id} />
        <FormHiddenText name="ParentID" value={nodeID} />
        <FormText className="col-12 col-xl-4" label="name" name="name" small autoFocus />
        <FormDate className="col-12 col-xl-4" label="began at" name="beganAt" maxDate={max} small required onChange={handleChangeBegin} />
        <FormDate className="col-12 col-xl-4" label="ended at" name="endedAt" minDate={min} small required onChange={handleChangeEnd} />
        <FormTextArea className="col-12" label="description" name="description" required small />
        <FormSubmitCancel small onCancel={onClose} />
      </div>
    </Form>
  )
}
