import React, { useCallback, useMemo, useState } from 'react'
import { Filter, useFilter } from './filter'
import { Menu, MenuItem } from '../menu/menu'
import { Card } from '../card/card'
import { useLoad } from '../hooks/load'
import { IconAdd } from '../icon/icon'
import { IconButtonCancel, IconButtonConfirm, IconButtonDelete, IconButtonEdit, IconButtonPurge, IconButtonRestore } from '../icon/icon-button'

const getValue = (row, col) => (typeof col.value === 'function' ? col.value(row) : row[col.value])

export const TableEdit = ({
  title,
  columns,
  apiList,
  formArgs,
  selectedFn,
  sortBy,
  showFilter,
  FormAdd,
  FormEdit,
  onLoad,
  onAdd,
  onEdit,
  onDelete,
  fnConfirmDelete,
  onRestore,
  onPurge
}) => {
  const [filterValue, updateFilterValue] = useFilter()

  const btnShowAdd = useMemo(() => typeof onAdd !== 'undefined', [onAdd])
  const btnShowEdit = useMemo(() => typeof onEdit !== 'undefined', [onEdit])
  const btnShowDelete = useMemo(() => typeof onDelete !== 'undefined', [onDelete])
  const btnShowRestore = useMemo(() => typeof onRestore !== 'undefined', [onRestore])
  const btnShowPurge = useMemo(() => typeof onPurge !== 'undefined', [onPurge])

  const callLoad = async () => {
    if (typeof onLoad === 'boolean') await apiList.get()
    else if (typeof onLoad === 'function') await onLoad()
  }

  const callAdd = async data => {
    if (typeof onAdd === 'boolean') await apiList.post(data)
    else if (typeof onAdd === 'function') await onAdd(data)
  }

  const callEdit = async data => {
    if (typeof onEdit === 'boolean') await apiList.put(data.id, data)
    else if (typeof onEdit === 'function') await onEdit(data)
  }

  const callDelete = async data => {
    if (typeof onDelete === 'boolean') await apiList.delete(data.id)
    else if (typeof onDelete === 'function') await onDelete(data)
  }

  const callRestore = async data => {
    if (typeof onRestore === 'boolean') await apiList.put(`${data.id}/restore`)
    else if (typeof onRestore === 'function') await onRestore(data)
  }
  const callPurge = async data => {
    if (typeof onPurge === 'boolean') await apiList.delete(`${data.id}/purge`)
    else if (typeof onPurge === 'function') await onPurge(data)
  }

  useLoad(() => callLoad(), [])

  const rows = useMemo(() => (apiList ? apiList.items : []), [apiList])
  const cols = useMemo(() => columns || [], [columns])

  const [showAdd, updateShowAdd] = useState(false)
  const [rowEdit, updateRowEdit] = useState()

  const handleAdd = () => updateShowAdd(true)
  const handleAddClose = () => updateShowAdd(false)
  const handleEdit = index => updateRowEdit(index)
  const handleEditClose = () => updateRowEdit(null)

  const handleAddSubmit = async data => {
    await callAdd(data)
    await callLoad()
    updateShowAdd(false)
  }

  const handleEditSubmit = async data => {
    await callEdit(data)
    await callLoad()
    updateRowEdit(null)
  }

  const handleDelete = async data => {
    const runDelete = async () => {
      await callDelete(data)
      await callLoad()
    }

    runDelete()

    //   if (fnConfirmDelete) {
    //     let message = fnConfirmDelete(data)
    //     if (message) modalContext.confirm('warning', message, undefined, () => runDelete())
    //     else runDelete()
    //   } else {
    //     await runDelete()
    //   }
  }

  const handleRestore = async data => {
    await callRestore(data)
    await callLoad()
  }

  const handlePurge = async data => {
    await callPurge(data)
    await callLoad()
  }

  const sort = useCallback((a, b) => (sortBy ? a[sortBy].localeCompare(b[sortBy]) : 0), [sortBy])

  const filter = useCallback(
    row => {
      if (!filterValue.length) {
        return true
      }

      for (let col of cols) {
        if (col.filter) {
          let value = getValue(row, col)
          if (value.toLowerCase().includes(filterValue.toLocaleLowerCase())) {
            return true
          }
        }
      }

      return false
    },
    [cols, filterValue]
  )

  const TableMenu = () =>
    <Menu small>
      <MenuItem Icon={IconAdd} text="Add" onClick={handleAdd} show={btnShowAdd} pin />
    </Menu>

  return (
    <React.Fragment>
      {showFilter && <Filter value={filterValue} onChange={updateFilterValue} />}

      <Card title={title} HeaderMenu={TableMenu}>
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr className="text-capitalize border-bottom">
                {cols.map((col, i) =>
                  <th key={i}>
                    {col.header}
                  </th>
                )}
                <th />
              </tr>
            </thead>
            <tbody>
              {showAdd &&
                <tr>
                  <TableRowAdd cols={cols} formArgs={formArgs} FormAdd={FormAdd} onSubmit={handleAddSubmit} onClose={handleAddClose} />
                </tr>}

              {rows.filter(filter).sort(sort).map((row, i) =>
                <tr key={i} className={`${selectedFn && selectedFn(row) && 'bg-primary'} border-bottom`}>
                  {rowEdit === i
                    ? <TableRowEdit cols={cols} row={row} formArgs={formArgs} FormEdit={FormEdit} onSubmit={handleEditSubmit} onClose={handleEditClose} />
                    : <React.Fragment>
                        {row.deletedAt
                          ? <TableRowDeleted
                              index={i}
                              cols={cols}
                              row={row}
                              showRestore={btnShowRestore}
                              showPurge={btnShowPurge}
                              onRestore={handleRestore}
                              onPurge={handlePurge}
                            />
                          : <TableRowView
                              index={i}
                              cols={cols}
                              row={row}
                              showEdit={btnShowEdit}
                              showDelete={btnShowDelete}
                              onEdit={handleEdit}
                              onDelete={handleDelete}
                            />}
                      </React.Fragment>}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </React.Fragment>
  )
}

const FormAddDefault = () => <span>FormAdd not set</span>

const TableRowAdd = ({ cols, formArgs, FormAdd, onSubmit, onClose }) => {
  const Component = useMemo(() => FormAdd || FormAddDefault, [FormAdd])

  return (
    <td colSpan={cols.length + 1}>
      <Card>
        <Component {...formArgs} onSubmit={onSubmit} onClose={onClose} />
      </Card>
    </td>
  )
}

const FormEditDefault = () => <span>FormEdit not set</span>

const TableRowEdit = ({ cols, row, formArgs, FormEdit, onSubmit, onClose }) => {
  const Component = useMemo(() => FormEdit || FormEditDefault, [FormEdit])

  return (
    <td colSpan={cols.length + 1}>
      <Card>
        <Component {...formArgs} row={row} onSubmit={onSubmit} onClose={onClose} />
      </Card>
    </td>
  )
}

const TableRowView = ({ index, cols, row, showEdit, showDelete, onEdit, onDelete }) => {
  const [confirm, updateConfirm] = useState(false)

  const handleEdit = () => onEdit(index)
  const handleDelete = () => updateConfirm(true)
  const handleCancel = () => updateConfirm(false)
  const handleConfirm = () => {
    updateConfirm(false)
    onDelete(row)
  }

  return (
    <React.Fragment>
      {cols.map((col, j) =>
        <td key={j}>
          {getValue(row, col)}
        </td>
      )}
      <td className="text-nowrap text-end">
        <IconButtonEdit hide={!showEdit || confirm} small onClick={handleEdit} />
        <IconButtonDelete hide={!showDelete || confirm} small onClick={handleDelete} />
        <IconButtonCancel hide={!confirm} small onClick={handleCancel} />
        <IconButtonConfirm hide={!confirm} small onClick={handleConfirm} />
      </td>
    </React.Fragment>
  )
}

const TableRowDeleted = ({ cols, row, showRestore, showPurge, onRestore, onPurge }) => {
  const handleRestore = () => onRestore(row)
  const handlePurge = () => onPurge(row)

  return (
    <React.Fragment>
      {cols.map((col, j) =>
        <td key={j}>
          {getValue(row, col)}
        </td>
      )}
      <td className="text-nowrap text-end">
        <IconButtonRestore hide={!showRestore} small onClick={handleRestore} />
        <IconButtonPurge hide={!showPurge} small onClick={handlePurge} />
      </td>
    </React.Fragment>
  )
}
