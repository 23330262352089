import { useCallback } from 'react'

export const useFitToPage = () => {
  return useCallback(agGrid => {
    if (agGrid.api) {
      let columnState = agGrid.api.getColumnState().filter(column => column.colId !== 'menu')

      columnState.forEach(column => {
        column.flex = 1
      })

      agGrid.api.applyColumnState({ state: columnState })
    }
  }, [])
}

export const useFitToColumns = () => {
  return useCallback(agGrid => {
    if (agGrid.api) {
      let columnState = agGrid.api.getColumnState()
      let columns = agGrid.api.getColumns()

      if (columns) {
        let sizeColumns = columns.filter(column => column.colId !== 'menu').map(column => column.getId())
        agGrid.api.autoSizeColumns(sizeColumns, false)
      }

      agGrid.api.applyColumnState(columnState)
    }
  }, [])
}
