import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListRoleOrg } from '../../../../../api-new/state/content'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnText } from '../../../../../shared/table-builder/table-columns'

export const RoleOrgListUser = () => {
  const { roleOrg } = useParams()

  const apiListRoleOrg = useApiListRoleOrg()

  const title = useMemo(() => `Role ${roleOrg}`, [roleOrg])

  const handleReady = async () => await apiListRoleOrg.get(`${roleOrg}/role`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={roleOrg}>
        <CardFullHeightScrollY title={title}>
          <TableAgClient apiList={apiListRoleOrg} onReady={handleReady}>
            <ColumnText field="roleName" />
            <ColumnText field="organizationName" />
            <ColumnText field="organizationType" header="type" />
            <ColumnText field="email" />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
