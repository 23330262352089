import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { useApiFormAsset, useApiListAsset } from '../../../../../../api-new/state/content'
import { Menu, MenuDelete, MenuEdit, MenuItem, MenuLink } from '../../../../../../shared/menu/menu'
import { IconAdd, IconDownload, IconList, IconRecycle } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { NodeAssetDetails } from './node-asset-details'
import { NodeAssetAdd } from './node-asset-add'
import { AssetRecycle } from './asset-recycle'
import { Purge } from '../../../../../../shared/page/page-purge'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useFileDownloadContent } from '../../../../../../shared/legacy/storage/hooks'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnSize, ColumnText } from '../../../../../../shared/table-builder/table-columns'

export const NodeAssetList = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const apiListAsset = useApiListAsset()

  const handleReady = () => apiListAsset.get(`${nodeID}/node`)

  const HeaderMenu = () =>
    <Menu>
      <MenuLink Icon={IconAdd} text="new asset" to={`${url}/add`} pin show={true} />
      <MenuLink Icon={IconRecycle} text="recycle" to={`${url}/recycle`} show={true} />
    </Menu>

  const fileDownloadContent = useFileDownloadContent()

  const handleDownload = asset => fileDownloadContent(asset.name, asset.key)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="assets">
        <CardFullHeightScrollY title="assets" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAsset} onReady={handleReady}>
            <ColumnText field="name" />
            <ColumnSize field="length" />
            <ColumnDateTime field="createdAt" />
            <ColumnDateTime field="updatedAt" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`${url}/details/${row.id}/edit`} show />
                  <MenuItem Icon={IconDownload} text="download" onClick={() => handleDownload(row)} show />
                  <MenuDelete to={`${url}/details/${row.id}/delete`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/details/:assetID`}>
        <NodeAssetDetails parentURL={url} />
      </Route>

      <Route path={`${url}/add`}>
        <NodeAssetAdd parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/recycle`}>
        <AssetRecycle parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/recycle/:purgeID/purge`}>
        <Purge useApiForm={useApiFormAsset} titleParam="name" successPath={`${url}/recycle`} />
      </Route>
    </React.Fragment>
  )
}
