import React from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuAdd, MenuDelete } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListProviderOrganinization } from '../../../../../api-new/state/auth'
import { IconList } from '../../../../../shared/icon/icon'
import { useRoleSysProvider } from '../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../shared/table-builder/table-columns'

export const ProviderOrganizationList = () => {
  const { providerID } = useParams()

  const apiListProviderOrganinization = useApiListProviderOrganinization()

  const roleSysProvider = useRoleSysProvider()

  const handleReady = async () => await apiListProviderOrganinization.get(`${providerID}/provider`)

  const handleDelete = async data => {
    await apiListProviderOrganinization.delete(`${data.providerID}/${data.organizationID}`)
    await apiListProviderOrganinization.get(`${providerID}/provider`)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to={`/admin/provider/list/edit/${providerID}/organization/add`} show={roleSysProvider.accessUpdate} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="organizations">
        <CardFullHeightScrollY title="organizations" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListProviderOrganinization} onReady={handleReady}>
            <ColumnText field="organizationName" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show={roleSysProvider.accessDelete} pin />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
