import React, { useMemo, useRef } from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
// import { IconButtonExportSummary, IconButtonExportDetails } from '../../../../shared/icon/icon-button'
import { Export } from '../../../../shared/component/export'
// import { useAssessmentReportList } from '../../../../api/state'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { useApiFormAssessment } from '../../../../api-new/state/assessment'
import { Menu, MenuExportCsv, MenuExportExcel, MenuItem, MenuRecycle } from '../../../../shared/menu/menu'
import { IconView } from '../../../../shared/icon/icon'
import { useApiListResultAssessmentCourse } from '../../../../api-new/state/assessment'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'

export const AssessmentCourseUsers = () => {
  const { url } = useRouteMatch()
  const { assessmentID } = useParams()

  const apiListTestResultAssessmentCourse = useApiListResultAssessmentCourse()

  const apiFormAssessment = useApiFormAssessment()

  const handlePath = () => apiFormAssessment.get(assessmentID)
  const handleReady = () => apiListTestResultAssessmentCourse.get(`users/${assessmentID}/assessment`)

  let title = useMemo(() => `${apiFormAssessment.form.title} users`, [apiFormAssessment.form])

  const refExportSummary = useRef()
  const refExportDetails = useRef()

  // const [assessmentReportListActions] = useAssessmentReportList()

  // const handleExportSummary = async () => {
  //   let results = await assessmentReportListActions.get(`${assessmentID}/summary`)
  //   refExportSummary.current.export(results)
  // }

  // const handleExportDetails = async () => {
  //   let results = await assessmentReportListActions.get(`${assessmentID}/details`)
  //   refExportDetails.current.export(results)
  // }

  // const HeaderComponent = () =>
  //   <React.Fragment>
  //     <IconButtonExportSummary primary onClick={handleExportSummary} />
  //     <IconButtonExportDetails primary onClick={handleExportDetails} />
  //   </React.Fragment>

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuRecycle to={`${url}/recycle`} show />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <React.Fragment>
      <Export name={title} type="summary" fileType="text/csv" ref={refExportSummary} />
      <Export name={title} type="details" fileType="text/csv" ref={refExportDetails} />
      <BreadcrumbView Icon={IconList} text={apiFormAssessment.form.title} onPath={handlePath}>
        <CardFullHeightScrollY title="Assessment Users" HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListTestResultAssessmentCourse} onReady={handleReady}>
            <ColumnText field="email" sortable filter />
            <ColumnText field="firstName" sortable filter />
            <ColumnText field="lastName" sortable filter />
            <ColumnText field="passed" sortable filter />
            <ColumnText field="attempts" sortable filter />
            <ColumnText field="avgDuration" name="Average Duration (Min:Sec)" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="attempts" Icon={IconView} to={`${url}/attemps/${assessmentID}/${row.userID}`} show pin />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
