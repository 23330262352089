import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListRoleSys } from '../../../../../api-new/state/content'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnText } from '../../../../../shared/table-builder/table-columns'

export const RoleSysListLogins = () => {
  const { roleSys } = useParams()

  const apiListRoleSys = useApiListRoleSys()

  const title = useMemo(() => `Role ${roleSys}`, [roleSys])

  const handleReady = () => apiListRoleSys.get(`${roleSys}/role`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={roleSys}>
        <CardFullHeightScrollY title={title}>
          <TableAgClient apiList={apiListRoleSys} onReady={handleReady}>
            <ColumnText field="roleName" />
            <ColumnText field="email" />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
