import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { Filter } from '../component/filter'
import { TableAG } from './table-ag'
import { getColumns } from './table-columns'

export const TableAgClient = forwardRef(({ children, apiList, sortCol, sortDesc, onReady, onRowClicked }, ref) => {
  const tableRef = useRef()

  const [filter, updateFilter] = useState('')

  let columns = useMemo(() => getColumns(children), [children])

  const data = useMemo(
    () => {
      if (!apiList) {
        return []
      }

      return apiList.items
    },
    [apiList]
  )

  useEffect(
    () => {
      if (tableRef.current.api) {
        tableRef.current.api.setGridOption('quickFilterText', filter)
      }
    },
    [filter]
  )

  return (
    <div className="d-flex flex-column h-100">
      <div>
        <Filter value={filter} onChange={updateFilter} />
      </div>
      <div className="flex-fill">
        <TableAG
          ref={tableRef}
          funcRef={ref}
          columns={columns}
          data={data}
          sortFirst
          sortCol={sortCol}
          sortDesc={sortDesc}
          onReady={onReady}
          onRefresh={onReady}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  )
})
