import React from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiListReportCustomOrg } from '../../../../../api-new/state/analytics'
import { IconTable } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { AnalyticCustomTable } from './table'

export const TableAll = () => {
  const { nodeID } = useParamsInt()

  const apiListReportCustomOrg = useApiListReportCustomOrg()

  const handleReady = () => apiListReportCustomOrg.get(`${nodeID}/course`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text="All Topics">
        <AnalyticCustomTable title="All Topics" apiList={apiListReportCustomOrg} onReady={handleReady} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
