import React from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'
import { Menu, MenuPurge, MenuRestore } from '../../../../shared/menu/menu'
import { useRouteMatch } from 'react-router-dom'
import { useApiListAnalyticParent } from '../../../../api-new/state/analytics'

export const AnalyticParentRecycle = () => {
  const { url } = useRouteMatch()

  const apiListAnalyticParent = useApiListAnalyticParent()

  const handleReady = () => apiListAnalyticParent.get('deleted')

  const handleRestore = async data => {
    await apiListAnalyticParent.put(`${data.id}/restore`)
    await apiListAnalyticParent.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Recycle">
        <CardFullHeightScrollY title="Recycle Bin">
          <TableAgClient apiList={apiListAnalyticParent} onReady={handleReady}>
            <ColumnText field="analyticParentType" header="Parent Type" />
            <ColumnText field="name" />
            <ColumnText field="description" />
            <ColumnText field="resource" />
            <ColumnText field="analyticTopicCount" header="Analytic Topics" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show />
                  <MenuPurge to={`${url}/${row.id}/purge`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
