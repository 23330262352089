import React, { useState } from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiListAnalyticEvent, useApiListAnalyticPeriod } from '../../../../../api-new/state/analytics'
import { useLoad } from '../../../../../shared/hooks/load'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { IconCalender } from '../../../../../shared/icon/icon'
import { VisTimelineEventPeriod } from '../vis-graph/vis-timeline-event-period'
import { Period } from '../period'

export const TimelineAnalytic = () => {
  const { nodeID, topicID, analyticID } = useParamsInt()

  const apiListAnalyticEventAnalytic = useApiListAnalyticEvent()
  const apiListAnalyticPeriod = useApiListAnalyticPeriod()

  const [selectedPeriodID, updateSelectedPeriodID] = useState()

  useLoad(() => apiListAnalyticEventAnalytic.get(`${nodeID}/course/${topicID}/topic/${analyticID}/analytic`), [nodeID])
  useLoad(() => apiListAnalyticPeriod.get(`${nodeID}/node`), [nodeID])

  const handleLoadPeriod = () => apiListAnalyticPeriod.get(`${nodeID}/node`)

  return (
    <BreadcrumbView text="Periods" Icon={IconCalender}>
      <CardFullHeightScrollY>
        <VisTimelineEventPeriod analytics={apiListAnalyticEventAnalytic.items} periods={apiListAnalyticPeriod.items} onSelectPeriod={updateSelectedPeriodID} />
        <Period apiList={apiListAnalyticPeriod} selectedPeriodID={selectedPeriodID} onLoad={handleLoadPeriod} />
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
