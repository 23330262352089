import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useCourseSessionGet } from '../state'
import { Card } from '../../../../../shared/card/card'
import { IconClickTimes } from '../../../../../shared/icon/icon-click'

const Item = ({ name, value, multiplyer }) => {
  return (
    <React.Fragment>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-1">
        <small className="font-weight-bold">
          {name}
        </small>
        {value
          ? <div>
              {value}
              &nbsp;
              {multiplyer}
            </div>
          : <div>---</div>}
      </div>
    </React.Fragment>
  )
}

export const SessionDetails = forwardRef((props, ref) => {
  const [show, updateShow] = useState(false)

  const [courseSessionGetActions, courseSessionGetState] = useCourseSessionGet()

  const hasSession = useMemo(() => !(props.sessionID === null || props.sessionID === 'all'), [props.sessionID])

  useEffect(
    () => {
      updateShow(false)
      hasSession && courseSessionGetActions.get(props.sessionID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasSession, props.sessionID]
  )

  const data = useMemo(
    () => {
      return courseSessionGetState.item
    },
    [courseSessionGetState.item]
  )

  useImperativeHandle(ref, () => ({
    openDetails: () => {
      updateShow(true)
    }
  }))

  const handleClose = () => {
    updateShow(false)
  }

  const HeaderMenu = () => <IconClickTimes onClick={handleClose} />

  return (
    <React.Fragment>
      {hasSession &&
        <React.Fragment>
          <div className={`collapse ${show ? 'show' : 'hide'}`}>
            <Card title="Session Details" HeaderMenu={HeaderMenu}>
              <div className="row m-3">
                <Item name="Operating System" value={data.os} />
                <Item name="Platform" value={data.platform} />
                <Item name="App Version" value={data.appVersion} />
                <Item name="App Environment" value={data.appEnvironment} />

                <Item name="Device Model" value={data.deviceModel} />
                <Item name="Device Type" value={data.deviceType} />

                <Item name="Graphics Card" value={data.graphicsCard} />
                <Item name="Graphics Card Memory Size" value={data.graphicsCardMemorySize} multiplyer="MB" />
                <Item name="Graphics Card Vendor" value={data.graphicsCardVendor} />
                <Item name="Is MultiPlayer" value={data.isMultiPlayer ? 'yes' : 'no'} />
                <Item name="Node Name" value={data.nodeName} />

                <Item name="Processor" value={data.processor} />
                <Item name="Processor Count" value={data.processorCount} />
                <Item name="Processor Frequency" value={data.processorFrequency} multiplyer="MHZ" />

                <Item name="System Memory Size" value={data.systemMemorySize} multiplyer="MB" />
                <Item name="Vr Device Model" value={data.vrDeviceModel} />
                <Item name="Vr Device Name" value={data.vrDeviceName} />
                <Item name="Vr Device Present" value={data.vrDevicePresent} />
                <Item name="Vr Device Refresh Rate" value={data.vrDeviceRefreshRate} />
                <Item name="Vr Device Tracking Origin Mode" value={data.vrDeviceTrackingOriginMode} />
                <Item name="Vr Device Tracking Space Type" value={data.vrDeviceTrackingSpaceType} />
              </div>
            </Card>
          </div>
        </React.Fragment>}
    </React.Fragment>
  )
})
