import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormDefaultValue } from './hooks/input-hooks'

let name = 'confirmPurge'
let confirm = 'purge'

export const FormTextConfirmPurge = ({ autoFocus }) => {
  const { register, watch, setError, clearErrors } = useFormContext()

  const formDisabled = useFormDisable()

  let watchValue = watch(name)

  useEffect(
    () => {
      if (!watchValue) {
        setError(name, { message: 'required' })
        return
      }

      if (watchValue.toLowerCase() === confirm) {
        clearErrors(name)
        return
      }

      setError(name, { message: 'mismatch' })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchValue]
  )

  useFormDefaultValue(name, '', true)

  return (
    <React.Fragment>
      <FormatLabel name={name} label="Confirm" subtext={`Type ${confirm} to confirm`} hasValue={!!watchValue} required={true}>
        <input
          className="form-control"
          placeholder={confirm}
          autoFocus={autoFocus}
          type="text"
          {...register(name, { required: true })}
          disabled={formDisabled}
        />
      </FormatLabel>
    </React.Fragment>
  )
}
