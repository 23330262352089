import React, { useMemo, useState } from 'react'
import { useLoad } from '../../hooks/load'
import { SwitchSmall } from '../../component/switch'
import { IconBegin, IconEnd, IconNext, IconPipe, IconPrevious, IconRefresh, IconTableFill, IconTableFit } from '../../icon/icon'
import { useFitToColumns, useFitToPage } from '../hooks'
// import { useTableContext } from '../table-context'

export const Footer = ({ gridRef, pagination, paginationValues, onPaginationChange, onRefresh }) => {
  return (
    <div className="d-flex flex-column flex-sm-row m-1">
      <div className="flex-fill flex-nowarp d-flex">
        <Reload onClick={onRefresh} />
        <IconPipe />
        <FitToColumns gridRef={gridRef} />
        <IconPipe />
        <FitToPage gridRef={gridRef} />
      </div>
      <div className="d-flex flex-wrap">
        <PaginationSwitch value={pagination} onChange={onPaginationChange} />
        <Pagination gridRef={gridRef} pagination={pagination} paginationValues={paginationValues} />
      </div>
    </div>
  )
}

// export const Footer = ({ tableRef, gridRef, pagination, onPaginationChange, onRefresh }) => {
//   const tableContext = useTableContext()

//   return (
//     <div className="d-flex flex-column flex-sm-row m-1">
//       <div className="d-flex flex-fill flex-nowrap">
//         {onRefresh && <Reload onClick={onRefresh} />}
//         <IconPipe />
//         <Advanced tableRef={tableRef} />
//         <IconPipe />
//         <Fit gridRef={gridRef} />
//         <IconPipe />
//         <Fill gridRef={gridRef} />
//       </div>
//       <div className="d-flex flex-fill justify-content-end">
//         {tableContext.isChunking &&
//           <div className="w-100">
//             <ChunkProgress />
//           </div>}

//         {tableContext.isLoaded &&
//           <div className="d-flex flex-wrap">
//             <PaginationSwitch value={pagination} onChange={onPaginationChange} />
//             <Pagination gridRef={gridRef} pagination={pagination} />
//           </div>}
//       </div>
//     </div>
//   )
// }

// const ChunkProgress = () => {
//   const tableContext = useTableContext()

//   let progress = useMemo(
//     () => {
//       if (!tableContext.chunkCount) {
//         return `0%`
//       }

//       return `${parseInt(tableContext.chunkProgress / tableContext.chunkCount * 100)}%`
//     },
//     [tableContext]
//   )

//   return (
//     <div className="progress m-1" role="progressbar">
//       {progress === `0%`
//         ? <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }} />
//         : <div className="progress-bar" style={{ width: progress }}>
//             {progress}
//           </div>}
//     </div>
//   )
// }

// const Advanced = ({ tableRef }) => {
//   const [selected, updateSelected] = useState(false)

//   const handleSetting = () => {
//     const selected = tableRef.current.toggleSideBar()
//     updateSelected(selected)
//   }

//   return (
//     <small className={`edxr-pointer ${selected && 'bg-primary rounded text-white'}`}>
//       <span className="mx-1 text-nowrap" onClick={handleSetting} data-bs-toggle="tooltip" data-bs-placement="top">
//         <IconSettings /> advanced
//       </span>
//     </small>
//   )
// }

const FitToColumns = ({ gridRef }) => {
  const fitToColumns = useFitToColumns()

  const resize = () => {
    if (gridRef.current.api.getColumns) {
      fitToColumns(gridRef.current)
    }
  }

  return (
    <small className="d-flex text-nowrap edxr-pointer" onClick={resize}>
      <span className="mx-1">
        <IconTableFit /> fit to columns
      </span>
    </small>
  )
}

const FitToPage = ({ gridRef }) => {
  const fitToPage = useFitToPage()

  const resize = () => {
    if (gridRef.current.api) {
      fitToPage(gridRef.current)
    }
  }

  return (
    <small className="d-flex text-nowrap edxr-pointer" onClick={resize}>
      <span className="mx-1">
        <IconTableFill /> fit to page
      </span>
    </small>
  )
}

const Reload = ({ onClick }) => {
  const [loading, updateLoading] = useState(false)

  const handleRefresh = async () => {
    if (!onClick) {
      return
    }

    updateLoading(true)
    let start = Date.now()
    await onClick()
    let end = Date.now()
    setTimeout(() => updateLoading(false), 1000 - (end - start))
  }

  return (
    <small className="d-flex text-nowrap edxr-pointer" onClick={handleRefresh}>
      <span className="mx-1">
        {loading
          ? <span className="spinner-border spinner-border-sm mx-1" role="status">
              <span className="visually-hidden">loading...</span>
            </span>
          : <IconRefresh />}
        reload
      </span>
    </small>
  )
}

const PaginationSwitch = ({ value, onChange }) => {
  return (
    <div className="mx-2">
      <SwitchSmall text="Pagination" value={value} onChange={onChange} />
    </div>
  )
}

const Pagination = ({ gridRef, pagination, paginationValues }) => {
  return (
    <React.Fragment>
      {!pagination && <PaginationAll paginationValues={paginationValues} />}
      {pagination &&
        <React.Fragment>
          <PaginationCurrent paginationValues={paginationValues} />
          <PaginationNav gridRef={gridRef} paginationValues={paginationValues} />
        </React.Fragment>}
    </React.Fragment>
  )
}

const PaginationNav = ({ gridRef, paginationValues }) => {
  const handleFirst = () => gridRef.current.api && gridRef.current.api.paginationGoToFirstPage()
  const handleLast = () => gridRef.current.api && gridRef.current.api.paginationGoToLastPage()
  const handleNext = () => gridRef.current.api && gridRef.current.api.paginationGoToNextPage()
  const handlePrevious = () => gridRef.current.api && gridRef.current.api.paginationGoToPreviousPage()

  return (
    <React.Fragment>
      <div className="d-flex mx-1">
        <small className="mx-1 edxr-pointer" onClick={handleFirst}>
          <IconBegin />{' '}
        </small>
        <small className="mx-1 edxr-pointer" onClick={handlePrevious}>
          <IconPrevious />
        </small>
        <small className="ms-1">Page</small>
        <small className="ms-1 fw-bold">
          {paginationValues.currentPage + 1}
        </small>
        <small className="ms-1">of</small>
        <small className="ms-1 fw-bold">
          {paginationValues.totalPages || 1}
        </small>
        <small className="mx-1 edxr-pointer" onClick={handleNext}>
          <IconNext />
        </small>
        <small className="mx-1 edxr-pointer" onClick={handleLast}>
          <IconEnd />
        </small>
      </div>
    </React.Fragment>
  )
}

const PaginationAll = ({ paginationValues }) => {
  let rowCount = useMemo(() => (paginationValues ? paginationValues.rowCount : 0), [paginationValues])

  return (
    <React.Fragment>
      <div className="d-flex mx-1">
        <small>Rows</small>
        <small className="mx-1 fw-bold">
          {rowCount}
        </small>
      </div>
    </React.Fragment>
  )
}

const PaginationCurrent = ({ paginationValues }) => {
  const [rowStart, updateRowStart] = useState(0)
  const [rowEnd, updateRowEnd] = useState(0)

  useLoad(
    () => {
      let start = paginationValues.currentPage * paginationValues.pageSize
      updateRowStart(start)

      let end = start + paginationValues.pageSize
      if (end > paginationValues.rowCount) {
        end = paginationValues.rowCount
      }

      updateRowEnd(end)
    },
    [paginationValues]
  )

  return (
    <React.Fragment>
      <div className="d-flex mx-1">
        <small className="mx-1 fw-bold">
          {rowStart + 1}
        </small>
        <small>to</small>
        <small className="mx-1 fw-bold">
          {rowEnd}
        </small>
        <small>of</small>
        <small className="mx-1 fw-bold">
          {paginationValues.rowCount}
        </small>
      </div>
    </React.Fragment>
  )
}
