import React from 'react'
import { IconAnalyticCustom } from '../../../shared/icon/icon'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../shared/card/card-full-height-scroll-y'
import { useApiListChunkReportCustomOrg } from '../../../api-new/state/analytics'
import { useParseJSON } from '../course/analytic-course-report/hook'
import { ColumnDateTime, ColumnSelector, ColumnText, ColumnYesNo } from '../../../shared/table-builder/table-columns'
import { TableAgInfinite } from '../../../shared/table-builder/table-ag-infinite'

export const AnalyticOrgCustom = () => {
  const apiListChunkReportCustomOrg = useApiListChunkReportCustomOrg()

  const handelGetRows = async (startRow, endRow, search, sortCol, sortDir) =>
    await apiListChunkReportCustomOrg.get(`range/${startRow}/${endRow}?search=${search}&sortCol=${sortCol}&sortDir=${sortDir}`)

  let parseValue = useParseJSON()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalyticCustom} text="ANALYTICS CUSTOM">
        <CardFullHeightScrollY title="Custom Analytics">
          <TableAgInfinite sortField="sessionID" sortDir="desc" onGetRows={handelGetRows}>
            <ColumnDateTime field="capturedAt" />
            <ColumnText field="sessionID" />
            <ColumnText field="nodeName" header="Course" />

            <ColumnText field="topicName" />
            <ColumnText field="topicDescription" />
            <ColumnText field="analyticName" />

            <ColumnSelector field="value" selector={row => parseValue(row.value)} />
            <ColumnText field="type" />

            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />

            <ColumnDateTime field="startOn" srtable />
            <ColumnDateTime field="endOn" />

            <ColumnText field="os" />
            <ColumnText field="platform" />
            <ColumnText field="appVersion" />

            <ColumnText field="deviceModel" />
            <ColumnText field="deviceType" />
            <ColumnText field="vrDeviceModel" />
            <ColumnText field="vrDeviceName" />
            <ColumnText field="vrDevicePresent" />
            <ColumnText field="vrDeviceTrackingOriginMode" />
            <ColumnText field="vrDeviceTrackingSpaceType" />
            <ColumnText field="vrDeviceRefreshRate" />
            <ColumnText field="graphicsCard" />
            <ColumnText field="graphicsCardMemorySize" />
            <ColumnText field="graphicsCardVendor" />
            <ColumnText field="processor" />
            <ColumnText field="processorCount" />
            <ColumnText field="processorFrequency" />
            <ColumnText field="systemMemorySize" />
            <ColumnYesNo field="isMultiPlayer" />

            <ColumnText field="locationCountry" />
            <ColumnText field="locationRegion" />
            <ColumnText field="locationCity" />

            <ColumnText field="groups" />
          </TableAgInfinite>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
