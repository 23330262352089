import { getAccessToken } from '../../storage/auth'
import { useFetchPost } from './fetch'

export const useDocAuthRedirect = () => {
  const fetchPost = useFetchPost()

  return async redirectDoc => {
    let url = process.env.REACT_APP_EDXR_DOCS
    let accessToken = getAccessToken()
    if (accessToken) {
      let body = JSON.stringify({ accessToken: accessToken })
      let hold = await fetchPost(`${url}/auth`, body)

      if (hold.ok) {
        if (redirectDoc) {
          window.location.href = redirectDoc
        } else {
          window.location.href = process.env.REACT_APP_EDXR_DOCS
        }
      }
    }
  }
}
