import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiFormAnalyticTopic } from '../../../../../api-new/state/analytics'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../../shared/card/card'
import { Form, FormSubmitCancel } from '../../../../../shared/form/form'
import { FormTextConfirmPurge } from '../../../../../shared/form/form-text-confirm-purge'

export const AnalyticCourseTopicPurge = () => {
  const { nodeID, analyticTopicID } = useParamsInt()

  const history = useHistory()

  const apiFormAnalyticTopic = useApiFormAnalyticTopic()

  const handleMatch = () => apiFormAnalyticTopic.get(`${nodeID}/node/${analyticTopicID}/deleted`)

  const handleSubmit = async data => {
    await apiFormAnalyticTopic.delete(`${data.id}/purge`)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  const showWarning = useMemo(() => !!apiFormAnalyticTopic.form.analyticCount, [apiFormAnalyticTopic])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Purge" onMatch={handleMatch}>
        <CardFullHeightScrollY title={`Purge ${apiFormAnalyticTopic.form.name}`}>
          {showWarning &&
            <Card>
              <span className="text-danger">
                This topic has {apiFormAnalyticTopic.form.analyticCount} analytics attached to it. Purging will remove these and associated data.
              </span>
            </Card>}
          <Form state={apiFormAnalyticTopic.form} onSubmit={handleSubmit} onRefresh={handleMatch}>
            <Card>
              <FormTextConfirmPurge autoFocus />
              <FormSubmitCancel onCancel={handleClose} />
            </Card>
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
