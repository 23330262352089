import { useContext } from 'react'
import { AuthContext } from '../../../../context/auth.context'
import { useRoleUser } from '../../../../api-new/state/content'
import { useHistory } from 'react-router-dom'

export const useProviderSignin = () => {
  const authContext = useContext(AuthContext)

  return async (providerLink, email, redirect, redirectDoc) => {
    let authPassave = await authContext.state.authProviderFormState.post()

    let redirectSigninURL = `${process.env.REACT_APP_SITE}/open/auth`
    let redirectCancelURL = `${process.env.REACT_APP_SITE}/auth/email`
    let redirectErrorURL = `${process.env.REACT_APP_SITE}/auth/error`

    if (redirect) {
      redirectSigninURL += `?redirect=${redirect}`
    }

    if (redirectDoc) {
      redirectSigninURL += `?redirect-doc=${redirectDoc}`
    }

    if (providerLink.signoutURL) {
      authContext.actions.setSignoutURL(providerLink.signoutURL)
    }

    // let url = `${process.env
    //   .REACT_APP_API_AUTH}/api/v1/auth-provider/${providerLink.identifier}?token=${authPassave.token}&redirect-signin-url=${redirectSigninURL}&redirect-cancel-url=${redirectCancelURL}&redirect-error-url=${redirectErrorURL}&redirect-doc=${redirectDoc}`

    let url = `${process.env
      .REACT_APP_API_AUTH}/api/v1/auth-provider/${providerLink.identifier}?token=${authPassave.token}&redirect-signin-url=${redirectSigninURL}&redirect-cancel-url=${redirectCancelURL}&redirect-error-url=${redirectErrorURL}`

    if (email) {
      url += `&email=${email}`
    }

    window.location = url
  }
}

export const useLoginRedirect = () => {
  const history = useHistory()

  const roleUser = useRoleUser()

  return async (redirect, redirectDoc) => {
    let roles = await roleUser.get()

    let isSysAdmin = roles.some(i => i.role === 'sys-admin')
    let isAdmin = roles.some(i => i.role === 'admin')
    let isAuthor = roles.some(i => i.role === 'author')
    let isOwner = roles.some(i => i.role === 'owner')
    let isTeacher = roles.some(i => i.role === 'teacher')
    // let isUser = roles.some(i => i.role === 'user')
    // let isLearner = roles && roles.some(i => i.role === 'learner')

    if (isSysAdmin || isAdmin || isAuthor || isOwner || isTeacher) {
      if (redirect) {
        history.push(redirect)
        return
      }

      if (redirectDoc) {
        window.location.href = redirectDoc
        return
      }

      history.push('/org/dashboard')
      return
    }

    history.push('/download/links')

    // if (roles && roles.length === 2 && isLearner) {
    //   debugger
    //   history.push('/download/links')
    //   return
    // }

    // debugger
    // if (redirect) {
    //   history.push(redirect)
    // } else {
    //   history.push('/org/dashboard')
    // }

    return
  }
}
