import React, { useRef } from 'react'
import { Menu, MenuDelete, MenuEdit, MenuExportCsv, MenuExportExcel, MenuRecycle, MenuTableSettingToggle } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconList } from '../../../../../../shared/icon/icon'
import { useApiListChunkErrorLog } from '../../../../../../api-new/state/content'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'

export function LogErrorList() {
  const apiListChunkErrorLog = useApiListChunkErrorLog()

  const handleReady = async () => {
    try {
      await apiListChunkErrorLog.get()
    } catch (error) {}
  }

  const handleDelete = async item => {
    try {
      await apiListChunkErrorLog.delete(item.id)
      await apiListChunkErrorLog.remove(item)
    } catch (error) {}
  }

  let tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuRecycle to="/admin/dashboard/log-error/list/recycle" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="errors">
        <CardFullHeightScrollY title="errors" HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListChunkErrorLog} sortCol="createdAt" sortDesc onReady={handleReady}>
            <ColumnDateTime field="createdAt" />
            <ColumnText field="method" />
            <ColumnText field="statusCode" />
            <ColumnText field="message" />
            <ColumnText field="email" />
            <ColumnText field="organization" />
            <ColumnText field="sessionID" hide />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/dashboard/log-error/list/edit/${row.id}`} show />
                  <MenuDelete onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
