import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { CourseAnalyticConfigure } from './analytic-course-configure/analytic-course-configure'
import { AnalyticCourseTopicList } from './analytic-course-configure/analytic-course-topic/analytic-course-topic-list'
import { AnalyticCourseTopicRecycle } from './analytic-course-configure/analytic-course-topic/analytic-course-topic-recycle'
import { AnalyticCourseTopicPurge } from './analytic-course-configure/analytic-course-topic/analytic-course-topic-purge'
import { AnalyticCourseTopicAdd } from './analytic-course-configure/analytic-course-topic/analytic-course-topic-add'
import { AnalyticCourseTopicEdit } from './analytic-course-configure/analytic-course-topic/analytic-course-topic-edit'
import { AnalyticCourseAnalyticList } from './analytic-course-configure/analytic-course-analytic/analytic-course-analytic-list'
import { AnalyticCourseAnalyticRecycle } from './analytic-course-configure/analytic-course-analytic/analytic-course-analytic-recycle'
import { AnalyticCourseAnalyticPurge } from './analytic-course-configure/analytic-course-analytic/analytic-course-analytic-purge'
import { AnalyticCourseAnalyticAdd } from './analytic-course-configure/analytic-course-analytic/analytic-course-analytic-add'
import { AnalyticCourseAnalyticEdit } from './analytic-course-configure/analytic-course-analytic/analytic-course-analytic-edit'
import { AnalyticGridTopic } from './analytic-course-report/analytic-grid-topic'
import { TableAll } from './analytic-course-report/table/table-all'
import { AnalyticGridAnalytic } from './analytic-course-report/analytic-grid-all'
import { TableTopic } from './analytic-course-report/table/table-topic'
import { TableAnalytic } from './analytic-course-report/table/table-analytic'
import { TimelineAll } from './analytic-course-report/timeline/timeline-all'
import { TimelineTopic } from './analytic-course-report/timeline/timeline-topic'
import { TimelineAnalytic } from './analytic-course-report/timeline/timeline-analytic'

export const AnalyticRoutes = () => {
  const { url } = useRouteMatch()

  return (
    <React.Fragment>
      <Route path={`${url}/analytic/:nodeID/configure`} component={CourseAnalyticConfigure} />

      <Route path={`${url}/analytic/:nodeID/configure/topic`} component={AnalyticCourseTopicList} />
      <Route path={`${url}/analytic/:nodeID/configure/topic/recycle`} component={AnalyticCourseTopicRecycle} />
      <Route path={`${url}/analytic/:nodeID/configure/topic/recycle/:analyticTopicID/purge`} component={AnalyticCourseTopicPurge} />
      <Route path={`${url}/analytic/:nodeID/configure/topic/add`} component={AnalyticCourseTopicAdd} />
      <Route path={`${url}/analytic/:nodeID/configure/topic/:id/edit`} component={AnalyticCourseTopicEdit} />

      <Route path={`${url}/analytic/:nodeID/configure/analytic`} component={AnalyticCourseAnalyticList} />
      <Route path={`${url}/analytic/:nodeID/configure/analytic/recycle`} component={AnalyticCourseAnalyticRecycle} />
      <Route path={`${url}/analytic/:nodeID/configure/analytic/recycle/:analyticID/purge`} component={AnalyticCourseAnalyticPurge} />
      <Route path={`${url}/analytic/:nodeID/configure/analytic/add`} component={AnalyticCourseAnalyticAdd} />
      <Route path={`${url}/analytic/:nodeID/configure/analytic/:id/edit`} component={AnalyticCourseAnalyticEdit} />

      <Route path={`${url}/analytic/:nodeID/report`} component={AnalyticGridTopic} />

      <Route path={`${url}/analytic/:nodeID/report/all`} component={TableAll} />
      <Route path={`${url}/analytic/:nodeID/report/all/timeline`} component={TimelineAll} />

      <Route path={`${url}/analytic/:nodeID/report/:topicID/analytic`} component={AnalyticGridAnalytic} />
      <Route path={`${url}/analytic/:nodeID/report/:topicID/analytic/table`} component={TableTopic} />
      <Route path={`${url}/analytic/:nodeID/report/:topicID/analytic/table/timeline`} component={TimelineTopic} />

      <Route path={`${url}/analytic/:nodeID/report/:topicID/analytic/:analyticID/table`} component={TableAnalytic} />
      <Route path={`${url}/analytic/:nodeID/report/:topicID/analytic/:analyticID/table/timeline`} component={TimelineAnalytic} />
    </React.Fragment>
  )
}
