import React from 'react'
import {  useAuthContext } from '../../../../context/auth.context'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { useLoginRedirect } from './hooks'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnText } from '../../../../shared/table-builder/table-columns'

export function LoginAccount() {

  const authContext = useAuthContext()

  const loginRedirect = useLoginRedirect()

  const handleReady = () => authContext.actions.getOrganizations()

  const handleClicked = async data => {
    try {
      await authContext.actions.changeOrganization(data)
     loginRedirect() 
    } catch {}
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="switch organization">
      <CardFullHeightScrollY title="select organization">
          <TableAgClient apiList={authContext.state.authOrganizationListState} hideFloatingFilter onRowClicked={handleClicked} onReady={handleReady}>
            <ColumnText field="name" headerName="Organization"  />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
