import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'

import { ModuleRegistry } from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import { AllEnterpriseModule } from 'ag-grid-enterprise'
import { themeAlpine, colorSchemeDarkBlue } from 'ag-grid-community'
import {} from '../component/filter'
import { Footer } from './component/table-footer'
import { usePageContext } from '../../context/page.context'
import { useFitToColumns, useFitToPage } from './hooks'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY)
ModuleRegistry.registerModules([AllEnterpriseModule])

export const TableAG = forwardRef(
  (
    {
      funcRef,
      columns,
      data,
      loading,
      rowModelType,
      infiniteInitialRowCount,
      cacheBlockSize,
      cacheOverflowSize,
      maxBlocksInCache,

      sortFirst,
      sortCol,
      sortDesc,

      onReady,
      onRefresh,
      onRowClicked
    },
    ref
  ) => {
    const pageContext = usePageContext()

    const defaultColDef = useMemo(() => {
      return {
        minWidth: 100,
        sortable: true,
        enablePivot: true,
        enableRowGroup: true
      }
    }, [])

    const gridOptions = useMemo(() => {
      return {}
    }, [])

    const autoSizeStrategy = {
      // type: 'fitGridWidth'
      // type: 'fitProvidedWidth'
      // type: 'fitCellContents'
    }

    const sideBarConfig = useMemo(() => {
      return {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            minWidth: 225,
            maxWidth: 225,
            width: 225
          }
        ],
        defaultToolPanel: 'columns',
        position: 'right'
      }
    }, [])

    const [sideBar, updateSideBar] = useState(null)

    useImperativeHandle(funcRef, () => ({
      exportCsv: () => ref.current.api.exportDataAsCsv(),
      exportExcel: () => ref.current.api.exportDataAsExcel(),
      toggleSideBar: () => {
        if (sideBar) updateSideBar(null)
        else updateSideBar(sideBarConfig)
      }
    }))

    const [pagination, updatePagination] = useState(false)

    const handleRefresh = () => onRefresh && onRefresh(ref.current)

    const [paginationValues, updatePaginationValues] = useState({})

    const handlePaginationChanged = agGrid => {
      let rowCount = agGrid.api.getDisplayedRowCount()
      const currentPage = agGrid.api.paginationGetCurrentPage()
      let pageSize = agGrid.api.paginationGetPageSize()
      let totalPages = agGrid.api.paginationGetTotalPages()

      updatePaginationValues({ rowCount, currentPage, pageSize, totalPages })
    }

    const theme = useMemo(
      () => (pageContext.theme.isDark ? themeAlpine.withPart(colorSchemeDarkBlue).withParams({ backgroundColor: 'rgb(25,27,31)' }) : themeAlpine),
      [pageContext.theme.isDark]
    )

    const handleRowClicked = row => onRowClicked && onRowClicked(row.data)

    const containerRef = useRef()

    const fitToPage = useFitToPage()
    const fitToColumns = useFitToColumns()

    const handleReady = async agGrid => {
      onReady && (await onReady(agGrid))

      if (sortFirst || sortCol) {
        let colId = agGrid.api.getColumns().filter(i => i.visible)[0].colId

        const columnState = {
          state: [
            {
              colId: sortCol || colId,
              sort: sortDesc ? 'desc' : 'asc'
            }
          ]
        }

        agGrid.api.applyColumnState(columnState)
      }

      let allColumnWidth = agGrid.api.getColumns().filter(column => column.visible).reduce((sum, column) => (sum += column.actualWidth), 0)
      let containerWidth = containerRef.current.offsetWidth

      if (containerWidth < allColumnWidth) {
        setTimeout(() => {
          fitToColumns(agGrid)
        }, 1000)
      } else {
        fitToPage(agGrid)
      }
    }

    return (
      <div ref={containerRef} className="d-flex flex-column h-100">
        <div className="flex-fill">
          <AgGridReact
            ref={ref}
            theme={theme}
            defaultColDef={defaultColDef}
            autoSizeStrategy={autoSizeStrategy}
            gridOptions={gridOptions}
            columnDefs={columns}
            rowData={data}
            loading={loading}
            rowModelType={rowModelType}
            pagination={pagination}
            paginationAutoPageSize={pagination}
            rowBuffer={0}
            cacheBlockSize={cacheBlockSize}
            cacheOverflowSize={cacheOverflowSize}
            infiniteInitialRowCount={infiniteInitialRowCount}
            maxBlocksInCache={maxBlocksInCache}
            overlayLoadingTemplate={`<div class="spinner-border text-primary" role="status"><span class="visually-hidden">Loading...</span></div>`}
            suppressPaginationPanel={true}
            onGridReady={handleReady}
            onPaginationChanged={handlePaginationChanged}
            pivotMode={false}
            sideBar={sideBar}
            rowGroupPanelShow={sideBar && 'always'}
            enableFilter={false}
            onRowClicked={handleRowClicked}
          />
        </div>
        <div>
          <Footer gridRef={ref} pagination={pagination} paginationValues={paginationValues} onPaginationChange={updatePagination} onRefresh={handleRefresh} />
        </div>
      </div>
    )
  }
)

// export const TableAG = forwardRef(
//   ({ columns, data, sortField, sortDir, loading, Filter, Footer, hideFloatingFilter, onReady, onRowClicked, onRefresh, onDragStopped }, ref) => {
//     const gridRef = useRef()

//     const tableContext = useTableContext()

//     useEffect(
//       () => {
//         if (gridRef.current.api) {
//           if (loading) gridRef.current.api.showLoadingOverlay()
//           else gridRef.current.api.hideOverlay()
//         }
//       },
//       [loading]
//     )

//     const sideBarConfig = useMemo(() => {
//       return {
//         toolPanels: [
//           'columns'
//           // {
//           //   id: 'test',
//           //   labelDefault: 'test',
//           //   labelKey: 'test',
//           //   toolPanel: () => <div>TEST</div>
//           // }
//         ]
//       }
//     }, [])

//     const [sideBar, updateSideBar] = useState(null)

//     useImperativeHandle(ref, () => ({
//       exportCsv: () => gridRef.current.api.exportDataAsCsv(),
//       exportExcel: () => gridRef.current.api.exportDataAsExcel(),
//       toggleSideBar: () => {
//         if (sideBar) {
//           updateSideBar(null)
//           gridRef.current.api.resetColumnState()
//           gridRef.current.api.setGridOption('pivotMode', false)
//         } else {
//           updateSideBar(sideBarConfig)
//         }
//         return !sideBar
//       }
//     }))

//     const [pagination, updatePagination] = useState(false)

//     const pageContext = usePageContext()

//     const _columns = useMemo(() => columns || [], [columns])

//     const defaultColDef = useMemo(() => {
//       return {
//         flex: 1,
//         minWidth: 100,
//         resizable: true,
//         // floatingFilter: !hideFloatingFilter,
//         filterParams: {
//           debounceMs: 100
//         }
//       }

//       // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])

//     let handleRowClicked = agRow => onRowClicked && onRowClicked(agRow.data)

//     const contanerRef = useRef()

//     const handleReady = async agGrid => {
//       const columnState = {
//         state: [
//           {
//             colId: sortField,
//             sort: sortDir
//           }
//         ]
//       }
//       agGrid.columnApi.applyColumnState(columnState)
//       if (onReady) {
//         await onReady(agGrid)
//       }
//       let containerWidth = contanerRef.current.offsetWidth
//       let allColunmWidth = agGrid.columnApi.api.getColumns().filter(i => !i.hide).reduce((sum, column) => (sum += column.actualWidth), 0)
//       if (containerWidth < allColunmWidth) {
//         //removed because it appears to be the default - remove id no issues come up
//         // setTimeout(() => {
//         //   let allColumnIds = agGrid.api.getColumns().filter(i => !i.colDef.hide).map(column => column.getId())
//         //   agGrid.api.autoSizeColumns(allColumnIds, false)
//         // }, 100)
//       } else {
//         setTimeout(() => {
//           // let hold = agGrid.api.getColumn('menu')
//           // debugger
//           agGrid.api.sizeColumnsToFit()
//           // agGrid.api.autoSizeColumns(['menu'], true)
//           // hold = agGrid.api.getColumn('menu')
//         }, 100)
//       }
//     }

//     const handlePaginationChanged = agGrid => {
//       let totalNumRows = agGrid.api.getDisplayedRowCount()
//       tableContext.updateDisplayedRowCount(totalNumRows)

//       const currentPage = agGrid.api.paginationGetCurrentPage()
//       tableContext.updateCurrentPage(currentPage)

//       let currentPageRows = agGrid.api.paginationGetPageSize()
//       tableContext.updatePageSize(currentPageRows)

//       let totalPages = agGrid.api.paginationGetTotalPages()
//       tableContext.updateTotalPages(totalPages)
//     }

//     return (
//       <div ref={contanerRef} className="d-flex flex-column h-100">
//         {Filter && <Filter gridRef={gridRef} />}
//         <div className={`flex-fill p-1 ${pageContext.theme.isDark ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'} h-100`}>
//           <AgGridReact
//             // columnMenu={'new'}
//             ref={gridRef}
//             columnDefs={_columns}
//             rowData={data}
//             defaultColDef={defaultColDef}
//             enableCharts={true}
//             enableRangeSelection={true}
//             cacheQuickFilter={true}
//             pagination={pagination}
//             paginationAutoPageSize={pagination}
//             onPaginationChanged={handlePaginationChanged}
//             suppressPaginationPanel={true}
//             onGridReady={handleReady}
//             onRowClicked={handleRowClicked}
//             onDragStopped={onDragStopped}
//             sideBar={sideBar}
//             rowGroupPanelShow={sideBar && 'always'}
//             overlayLoadingTemplate={`<div class="spinner-border text-primary" role="status"><span class="visually-hidden">Loading...</span></div>`}
//           />
//         </div>
//         {Footer && <Footer tableRef={ref} gridRef={gridRef} pagination={pagination} onPaginationChange={updatePagination} onRefresh={onRefresh} />}
//       </div>
//     )
//   }
// )
