import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Menu, MenuAdd, MenuRemove } from '../../../../../../shared/menu/menu'
import { useApiListGroupUser } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconUsers } from '../../../../../../shared/icon/icon'
import { useModalActionID } from '../../../../../../shared/component/modal-dialog'
import { PermissionGroupUserForGroupModal } from '../../../../../../shared/permission-modal/permission-group-user-for-group-modal'
import { useRoleOrgGroupUser } from '../../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'

export const GroupListUser = () => {
  const { groupID } = useParamsInt()

  const apiListGroupUser = useApiListGroupUser()

  const [target, show, hide] = useModalActionID()

  const handleReady = async () => await apiListGroupUser.get(`${groupID}/group`)

  const handleAddShow = () => show()
  const handleClose = () => hide()

  const handleClosed = async () => apiListGroupUser.get(`${groupID}/group`)

  const handleDelete = async item => {
    await apiListGroupUser.delete(`${item.groupID}/${item.userID}`)
    await apiListGroupUser.get(`${groupID}/group`)
  }

  const roleOrgGroupUser = useRoleOrgGroupUser()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" onClick={handleAddShow} show={roleOrgGroupUser.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <PermissionGroupUserForGroupModal target={target} groupID={groupID} onClose={handleClose} onClosed={handleClosed} />
      <BreadcrumbView Icon={IconUsers} text="users">
        <CardFullHeightScrollY title="group users" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListGroupUser} onReady={handleReady}>
            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRemove text="remove" onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
