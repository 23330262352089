import React, { useState } from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiListAnalyticEvent, useApiListAnalyticPeriod } from '../../../../../api-new/state/analytics'
import { useLoad } from '../../../../../shared/hooks/load'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Period } from '../period'
import { IconCalender } from '../../../../../shared/icon/icon'
import { VisTimelineEventPeriod } from '../vis-graph/vis-timeline-event-period'

export const TimelineAll = () => {
  const { nodeID } = useParamsInt()

  const apiListAnalyticEventTopic = useApiListAnalyticEvent()
  const apiListAnalyticEventAnalytic = useApiListAnalyticEvent()
  const apiListAnalyticPeriod = useApiListAnalyticPeriod()

  const [selectedPeriodID, updateSelectedPeriodID] = useState()

  useLoad(() => apiListAnalyticEventTopic.get(`${nodeID}/course/topic`), [nodeID])
  useLoad(() => apiListAnalyticEventAnalytic.get(`${nodeID}/course/analytic`), [nodeID])
  useLoad(() => apiListAnalyticPeriod.get(`${nodeID}/node`), [nodeID])

  const handleLoadPeriod = () => apiListAnalyticPeriod.get(`${nodeID}/node`)

  return (
    <BreadcrumbView text="Periods" Icon={IconCalender}>
      <CardFullHeightScrollY>
        <VisTimelineEventPeriod
          topics={apiListAnalyticEventTopic.items}
          analytics={apiListAnalyticEventAnalytic.items}
          periods={apiListAnalyticPeriod.items}
          onSelectPeriod={updateSelectedPeriodID}
        />
        <Period apiList={apiListAnalyticPeriod} selectedPeriodID={selectedPeriodID} onLoad={handleLoadPeriod} />
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
