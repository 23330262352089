import React, { useEffect, useMemo, useState } from 'react'
import { Menu, MenuExport, MenuItem } from '../../../../../shared/menu/menu'
import { IconDetails } from '../../../../../shared/icon/icon'
import { Card } from '../../../../../shared/card/card'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnSelector, ColumnText } from '../../../../../shared/table-builder/table-columns'
import moment from 'moment'

const aggergateUnique = items => {
  let uniqueSteps = {}

  items.forEach(item => {
    if (!uniqueSteps[item.index]) {
      uniqueSteps[item.index] = []
    }
    uniqueSteps[item.index].push(item)
  })

  var stepData = {}

  for (let key of Object.keys(uniqueSteps)) {
    let items = uniqueSteps[key]

    let course = ''

    let stepIndex = 0
    let stepName = ''
    let sumObjectives = 0
    let sumObjectivesCompleted = 0
    let sumAttachments = 0
    let sumAttachmentsCompleted = 0
    let sumDuration = 0

    for (let item of items) {
      sumObjectives += item.objectives
      sumObjectivesCompleted += item.objectivesCompleted
      sumAttachments += item.attachments
      sumAttachmentsCompleted += item.attachmentsCompleted
      sumDuration += item.duration
    }

    course = items[0].nodeName

    stepIndex = items[0] ? items[0].index : -1
    stepName = items[0] ? items[0].stepName : 'unknown'

    let avgObjectives = sumObjectives / items.length
    let avgObjectivesComplete = sumObjectives ? (sumObjectivesCompleted / sumObjectives * 100).toFixed(0) + '%' : '--'
    let avgAttachments = sumAttachments / items.length
    let avgAttachmentsComplete = sumAttachments ? (sumAttachmentsCompleted / sumAttachments * 100).toFixed(0) + '%' : '--'
    let avgDuration = sumDuration / items.length

    stepData[key] = {
      course: course,

      stepIndex: stepIndex,
      stepName: stepName,
      avgObjectives: Math.floor(avgObjectives),
      avgObjectivesComplete: avgObjectivesComplete,
      avgAttachments: Math.floor(avgAttachments),
      avgAttachmentsComplete: avgAttachmentsComplete,
      avgDuration: moment.utc(moment.duration(avgDuration).as('millisecond')).format('mm:ss')
    }
  }

  let display = []

  Object.keys(stepData).forEach(key => {
    display.push(stepData[key])
  })

  return display
}

const aggergateSession = (session, items) => {
  items = items.filter(i => i.sessionID === parseInt(session))

  let display = []

  for (let item of items) {
    var stepData = {
      course: item.nodeName,
      email: item.userName,
      firstName: item.firstName,
      lastName: item.lastName,
      deviceType: item.deviceType,
      os: item.os,
      startOn: item.startOn,
      endOn: item.endOn,

      stepIndex: item.index,
      stepName: item.stepName,
      avgObjectives: item.objectives,
      avgObjectivesComplete: item.objectivesCompleted,
      avgAttachments: item.attachments,
      avgAttachmentsComplete: item.attachmentsCompleted,
      avgDuration: moment.utc(moment.duration(item.duration).as('millisecond')).format('mm:ss')
    }

    display.push(stepData)
  }

  return display
}

export function Table({ sessionID, items, onShowDetails, onExport, onReady }) {
  const [display, updateDisplay] = useState([])

  const hasSession = useMemo(() => !(sessionID === null || sessionID === 'all'), [sessionID])

  useEffect(
    () => {
      if (hasSession) {
        return updateDisplay(aggergateSession(sessionID, items))
      } else {
        return updateDisplay(aggergateUnique(items))
      }
    },
    [hasSession, sessionID, items]
  )

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconDetails} text="details" onClick={onShowDetails} show={sessionID} pin />
      <MenuExport text="export" onClick={onExport} pin show />
    </Menu>

  return (
    <React.Fragment>
      <Card title="steps" height="100%" HeaderMenu={HeaderMenu}>
        <TableAgClient apiList={{ items: display }} onReady={onReady}>
          <ColumnSelector field="stepIndex" header="Step" selector={row => row.stepIndex + 1} sortable />
          <ColumnText field="stepName" header="Name" />
          <ColumnText field="avgObjectives" header="Step Objectives" />
          <ColumnText field="avgObjectivesComplete" header={hasSession ? 'Objectives Completed' : 'Avg Objectives Completion %'} />
          <ColumnText field="avgAttachments" header="Step Attachments" sortable />
          <ColumnText field="avgAttachmentsComplete" header={hasSession ? 'Attachments Viewed' : 'Avg Attachments Viewed %'} />
          <ColumnText field="avgDuration" header={hasSession ? 'Time per Step' : 'Avg Time per Step'} />
        </TableAgClient>
      </Card>
    </React.Fragment>
  )
}
