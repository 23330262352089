import React from 'react'
import { useHistory } from 'react-router-dom'
import { useApiFormAnalyticParent, useApiListAnalyticParentType } from '../../../../api-new/state/analytics'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { IconAdd } from '../../../../shared/icon/icon'
import { Card } from '../../../../shared/card/card'
import { Form, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'
import { FormSelectObj } from '../../../../shared/form/form-select-obj'
import { useLoad } from '../../../../shared/hooks/load'

export const AnalyticParentAdd = () => {
  const history = useHistory()

  const apiFormAnalyticParent = useApiFormAnalyticParent()
  const apiListAnalyticParentType = useApiListAnalyticParentType()

  useLoad(() => apiListAnalyticParentType.get(), [])

  const handleSubmit = async data => {
    await apiFormAnalyticParent.post(data)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="Add">
        <CardFullHeightScrollY title="Add Analytic Parent">
          <Card>
            <Form state={apiFormAnalyticParent.form} onSubmit={handleSubmit}>
              <FormSelectObj
                label="parent type"
                state={apiListAnalyticParentType}
                name="analyticParentTypeID"
                itemName="name"
                itemValue="id"
                valueAsNumber
                required
              />
              <FormText label="name" name="name" autoFocus />
              <FormTextArea label="description" name="description" />
              <FormText label="resource" name="resource" />
              <FormSubmitCancel onCancel={handleClose} />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
