import React from 'react'
import { Route, useRouteMatch } from 'react-router'
import { AnalyticCourseGeneral } from './analytic-course-general'
import { AnalyticView } from './course/analytic-view'

export function AnalyticLegacyLayout() {
  const { url } = useRouteMatch()

  return (
    <React.Fragment>
      <Route path={`${url}/analytic-legacy/course/:nodeID`} component={AnalyticView} />
      <Route path={`${url}/analytic-general/:nodeID`} component={AnalyticCourseGeneral} />
    </React.Fragment>
  )
}
