import React, { useMemo } from 'react'
import { CourseHeader, Footer, FooterButton, Widget } from '../../../../../../shared/chart/widget'
import { IconAnalyticCustom } from '../../../../../../shared/icon/icon'
import { ChartBar } from '../../../../../../shared/chart/chart'
import { chartColorMain } from '../../../../../../shared/chart/chart-color'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useRouteMatch } from 'react-router-dom'
import { useApiListGraphCustomCourse } from '../../../../../../api-new/state/analytics'

export const AnalyticCustomCourse = ({ nodeID, showConfigButton }) => {
  const { url } = useRouteMatch()

  const apiListGraphCustomCourse = useApiListGraphCustomCourse()

  useLoad(() => apiListGraphCustomCourse.get(`${nodeID}/node`), [nodeID])

  const chartData = useMemo(
    () => {
      if (!apiListGraphCustomCourse.items.length) {
        return { labels: [], data: undefined, value: 0 }
      }

      let value = apiListGraphCustomCourse.items.reduce((a, i) => a + i.count, 0)
      let sorted = apiListGraphCustomCourse.items.sort((a, b) => a.topicAnalytic.localeCompare(b.topicAnalytic))
      const labels = sorted.map(i => i.topicAnalytic)
      const data = sorted.map(i => i.count)

      const dataSet = {
        data: data,
        borderColor: chartColorMain,
        backgroundColor: chartColorMain
      }

      return { labels: labels, data: dataSet, value: value }
    },
    [apiListGraphCustomCourse.items]
  )

  return (
    <Widget
      header={<CourseHeader Icon={IconAnalyticCustom} text="Custom Analytics" value={chartData.value} />}
      body={<ChartBar labels={chartData.labels} data={chartData.data} displayY />}
      footer={
        <React.Fragment>
          <Footer>
            <FooterButton text="Reports" to={`${url}/analytic/${nodeID}/report`} />
            <FooterButton text="Configure" to={`${url}/analytic/${nodeID}/configure`} />
          </Footer>
        </React.Fragment>
      }
    />
  )
}
