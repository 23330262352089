import React from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiListReportCustomOrg } from '../../../../../api-new/state/analytics'
import { IconTable } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { AnalyticCustomTable } from './table'
import { useLoad } from '../../../../../shared/hooks/load'
import { useApiFormAnalytic } from '../../../../../api-new/state/analytics'

export const TableAnalytic = () => {
  const { analyticID } = useParamsInt()

  const apiFormAnalytic = useApiFormAnalytic()
  const apiListReportCustomOrg = useApiListReportCustomOrg()

  useLoad(() => apiFormAnalytic.get(analyticID), [])

  const handleReady = () => apiListReportCustomOrg.get(`${analyticID}/analytic`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text={apiFormAnalytic.form.name}>
        <AnalyticCustomTable title={apiFormAnalytic.form.name} apiList={apiListReportCustomOrg} onReady={handleReady} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
