import React from 'react'
import { useApiListAnalytic } from '../../../../../api-new/state/analytics'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'
import { Menu, MenuAdd, MenuDelete, MenuEdit, MenuRecycle } from '../../../../../shared/menu/menu'
import { useRouteMatch } from 'react-router-dom'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'

export const AnalyticCourseAnalyticList = () => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const apiListAnalytic = useApiListAnalytic()

  const handleReady = () => apiListAnalytic.get(`${nodeID}/node`)

  const handleDelete = async data => {
    await apiListAnalytic.delete(data.id)
    await apiListAnalytic.get(`${nodeID}/node`)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd to={`${url}/add`} show />
      <MenuRecycle to={`${url}/recycle`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Analytics">
        <CardFullHeightScrollY title="Analytics" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAnalytic} onReady={handleReady}>
            <ColumnText field="name" />
            <ColumnText field="analyticTopic" header="Topic" />
            <ColumnText field="analyticType" header="Type" />
            <ColumnText field="description" />
            <ColumnText field="analyticDataCount" header="Data Count" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`${url}/${row.id}/edit`} show />
                  <MenuDelete onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
