import React from 'react'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthContext } from '../../../../../../context/auth.context'
import { useApiListLoginUser } from '../../../../../../api-new/state/content'
import { Menu, MenuAdd, MenuDelete, MenuEdit, MenuItem } from '../../../../../../shared/menu/menu'
import { IconEdit, IconImpersonate } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useRoleSysLogin } from '../../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'

export function OrganizationUserList() {
  const { loginID } = useParamsInt()

  const { url } = useRouteMatch()

  const authContext = useAuthContext()

  const apiListLoginUser = useApiListLoginUser()

  const roleSysLogin = useRoleSysLogin()

  const handleReady = () => apiListLoginUser.get(`${loginID}/login`)

  const handleImpersonate = async item => {
    const authToken = await authContext.actions.impersonateUser(item.organizationID, item.id)
    let url = `${process.env.REACT_APP_SITE}/redirect/impersonate/${authToken.refreshToken}`
    window.open(url, '_blank', 'noopener, noreferrer')
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add login to org" to={`${url}/add`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="organizations">
        <CardFullHeightScrollY title="organizations" height={500} HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListLoginUser} hideFloatingFilter onReady={handleReady}>
            <ColumnText field="organizationName" header="organization" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem Icon={IconImpersonate} text="Impersonate User" onClick={() => handleImpersonate(row)} show />
                  <MenuEdit text="Edit User" to={`/admin/login/list/edit/${loginID}/organizations/edit/${row.id}`} show={roleSysLogin.accessUpdate} />
                  <MenuDelete text="Purge User" to={`${url}/edit/${row.id}/purge/${row.id}`} show={roleSysLogin.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
