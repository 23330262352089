import React, { useMemo, useState } from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Card } from '../../../../shared/card/card'
import { PassingScore } from './assessment-manage-edit/passing-score'
import { AllowedAttempts } from './assessment-manage-edit/allowed-attempts'
import { QuestionList } from './assessment-manage-edit/question-list/question-list'
import { AssessmentType } from './assessment-manage-edit/assessment-type'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Menu, MenuItem } from '../../../../shared/menu/menu'
import { Form, FormSubmitBack, FormText, FormTextArea, FormCheckbox } from '../../../../shared/form/form'
import { useManageAssessmentForm } from '../../../../api/state'
import { useRouteMatch } from 'react-router-dom'
import { useHistory } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { IconEdit, IconPublish } from '../../../../shared/icon/icon'
import { ModalDialog, useModalActions } from '../../../../shared/component/modal-dialog'
import { ButtonPrimary } from '../../../../shared/component/button'
import { ButtonSecondary } from '../../../../shared/legacy/button'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'

const confirmPublishDialog = 'confirmPublishDialog'

export const AssessmentManageEdit = () => {
  const history = useHistory()

  const { url } = useRouteMatch()

  const { assessmentID } = useParamsInt()

  const [manageAssessmentFormActions, manageAssessmentFormState] = useManageAssessmentForm()

  const handlePath = () => manageAssessmentFormActions.get(assessmentID)

  const modalActions = useModalActions(confirmPublishDialog)

  const handlePublish = () => modalActions.show()

  const handleConfirm = async () => {
    await manageAssessmentFormActions.putArgs({ path: `${assessmentID}/publish`, data: manageAssessmentFormState.form })
    history.goBack()
  }

  const [hasErrors, updateHasErrors] = useState(null)
  const [isDirty, updateIsDirty] = useState(null)

  const handleSubmit = async data => {
    await manageAssessmentFormActions.put(data)
  }

  const [questionCount, updateQuestionCount] = useState(0)

  const handleQuestionChange = questions => updateQuestionCount(questions.length)

  const [type, updateType] = useState('')

  const typeSelected = useMemo(() => !!type, [type])

  const showCorrectChoice = useMemo(() => type === 'quiz', [type])

  const allowedAttemptsLabel = useMemo(
    () => {
      switch (type) {
        case 'quiz':
          return 'allowed attempts'
        case 'survey':
          return 'max submissions'
        default:
          return 'allowed attempts'
      }
    },
    [type]
  )

  const disablePublish = useMemo(
    () => {
      let isPublished = !!manageAssessmentFormState.form.publishedOn
      let hasType = !!manageAssessmentFormState.form.type
      let hasQuestions = questionCount !== 0
      return isPublished || !hasType || !hasQuestions || hasErrors || isDirty
    },
    [manageAssessmentFormState.form, questionCount, hasErrors, isDirty]
  )

  const showAssignmentMenu = useMemo(() => manageAssessmentFormState.form.courseVersion === 2 && manageAssessmentFormState.form.hasNodeCourse, [
    manageAssessmentFormState.form
  ])

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconEdit} text="assignment" to={`${url}/assignment`} show={showAssignmentMenu} pin />
      <MenuItem Icon={IconPublish} text="publish" onClick={handlePublish} show disabled={disablePublish} pin />
    </Menu>

  return (
    <React.Fragment>
      <ConfirmModal onConfirm={handleConfirm} />
      <BreadcrumbView Icon={IconEdit} text={`${manageAssessmentFormState.form.title}`} block={manageAssessmentFormState.form.publishedOn} onPath={handlePath}>
        <CardFullHeightScrollY title="Assessment Edit" HeaderMenu={HeaderMenu}>
          <Form state={manageAssessmentFormState.form} enforceBlocking onIsDirty={updateIsDirty} onHasError={updateHasErrors} onSubmit={handleSubmit}>
            <AssessmentType label="type" name="type" onChange={updateType} />
            <FormText label="title" name="title" readOnly={!typeSelected} required />
            <FormTextArea label="introduction" name="introduction" readOnly={!typeSelected} />

            <QuestionList label="questions" name="contentMeta" readOnly={!typeSelected} showCorrectChoice={showCorrectChoice} onChange={handleQuestionChange} />

            {type === 'quiz' &&
              <React.Fragment>
                <FormCheckbox label="Allow Review" name="allowReview" readOnly={!typeSelected} />
                <PassingScore label="passing score" name="passingScore" questionCount={questionCount} readOnly={!typeSelected} />
              </React.Fragment>}

            <AllowedAttempts label={allowedAttemptsLabel} name="allowedAttempts" readOnly={!typeSelected} />

            <FormSubmitBack text="update" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const ConfirmModal = ({ onConfirm }) => {
  const modalActions = useModalActions(confirmPublishDialog)

  const handleConfirm = () => {
    modalActions.hide()
    onConfirm && onConfirm()
  }

  const handleCancel = () => modalActions.hide()

  return (
    <ModalDialog target={confirmPublishDialog} header="Confirm Publish">
      <div className="mt-2 mb-4">Are you sure? Once published, the assessment cannot be changed without clearing its user results.</div>
      <div className="my-2">
        <ButtonPrimary text="confirm" onClick={handleConfirm} />
        <ButtonSecondary text="cancel" onClick={handleCancel} />
      </div>
    </ModalDialog>
  )
}
