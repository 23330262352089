import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconRole } from '../../../../../shared/icon/icon'
import { Menu, MenuAdd, MenuDelete, MenuEdit } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListRoleSys } from '../../../../../api-new/state/content'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnLink, ColumnMenu } from '../../../../../shared/table-builder/table-columns'

export const RoleSysList = () => {
  const apiListRoleSys = useApiListRoleSys()

  const handleReady = async () => await apiListRoleSys.get()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add role" to={'/root/role-sys/list/add'} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconRole} text="ROLES">
        <CardFullHeightScrollY title="Roles" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListRoleSys} onReady={handleReady}>
            <ColumnLink field="name" to={row => `/root/role-sys/list/logins/${row.name}/role`} />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/root/role-sys/list/edit/${row.id}`} show />
                  <MenuDelete to={`/root/role-sys/list/edit/${row.id}/delete`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
