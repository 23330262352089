import { useMemo, useState } from 'react'
import { useApiListNodeGroup, useApiListNodeOrg } from '../../api-new/state/content'
import { ModalDialog } from '../component/modal-dialog'
import { TextFilter } from './text-filter'
import { IconMinus, IconPlus } from '../icon/icon'
import { ButtonPrimary } from '../component/button'

export const PermissionNodeGroupForGroupModal = ({ target, groupID, onClose, onClosed }) => {
  const [filter, updateFilter] = useState('')

  const apiListNodeOrg = useApiListNodeOrg()
  const apiListNodeGroup = useApiListNodeGroup()

  const filteredItems = useMemo(
    () => {
      let lowerFilter = filter.toLowerCase()
      return apiListNodeOrg.items.filter(i => i.name.toLowerCase().includes(lowerFilter))
    },
    [apiListNodeOrg.items, filter]
  )

  const handleOpen = () => {
    apiListNodeOrg.get()
    apiListNodeGroup.get(`${groupID}/group`)
  }
  const handleClose = () => onClose && onClose()

  const handleAdd = async nodeID => {
    await apiListNodeGroup.post({ nodeID: nodeID, groupID: groupID })
    await apiListNodeGroup.get(`${groupID}/group`)
  }

  const handleRemove = async nodeID => {
    await apiListNodeGroup.delete(`${nodeID}/${groupID}`)
    await apiListNodeGroup.get(`${groupID}/group`)
  }

  return (
    <ModalDialog target={target} header="Add Content" fullScreen onOpen={handleOpen} onClose={onClosed}>
      <div className="d-flex flex-column h-100">
        <div>
          <TextFilter value={filter} onChange={updateFilter} />
        </div>
        <div className="flex-fill overflow-auto p-2">
          {filteredItems.map((node, i) =>
            <SelectItem key={i} node={node} selectedNodeGroups={apiListNodeGroup.items} onAdd={handleAdd} onRemove={handleRemove} />
          )}
        </div>
        <div>
          <ButtonPrimary onClick={handleClose}>close</ButtonPrimary>
        </div>
      </div>
    </ModalDialog>
  )
}

const SelectItem = ({ node, selectedNodeGroups, onAdd, onRemove }) => {
  const isSelected = useMemo(
    () => {
      return selectedNodeGroups.findIndex(selectedNodeGroup => selectedNodeGroup.nodeID === node.id) !== -1
    },
    [selectedNodeGroups, node]
  )

  const handleClick = () => {
    if (isSelected) {
      onRemove && onRemove(node.id)
    } else {
      onAdd && onAdd(node.id)
    }
  }

  return (
    <div className="p-1 border-bottom" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <span className="px-1">
        {isSelected ? <IconMinus /> : <IconPlus />}
      </span>
      <span>
        {node.name}
      </span>
    </div>
  )
}
