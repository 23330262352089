import { useMemo } from 'react'
import moment from 'moment'
import { Card } from '../../../../../shared/card/card'

const svgAdd = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM192 240c13.3 0 24 10.7 24 24v48h48c13.3 0 24 10.7 24 24s-10.7 24-24 24H216v48c0 13.3-10.7 24-24 24s-24-10.7-24-24V360H120c-13.3 0-24-10.7-24-24s10.7-24 24-24h48V264c0-13.3 10.7-24 24-24z"/></svg> `
const svgEdit = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V285.7l-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/></svg>`
const svgDelete = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM111 239c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>`
const svgUnknown = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zM368 321.6V328c0 8.8 7.2 16 16 16s16-7.2 16-16v-6.4c0-5.3 4.3-9.6 9.6-9.6h40.5c7.7 0 13.9 6.2 13.9 13.9c0 5.2-2.9 9.9-7.4 12.3l-32 16.8c-5.3 2.8-8.6 8.2-8.6 14.2V384c0 8.8 7.2 16 16 16s16-7.2 16-16v-5.1l23.5-12.3c15.1-7.9 24.5-23.6 24.5-40.6c0-25.4-20.6-45.9-45.9-45.9H409.6c-23 0-41.6 18.6-41.6 41.6z"/></svg`

// export const eventMethodHeader = method => {
//   switch (method) {
//     case 'POST':
//       return 'Create'
//     case 'PUT':
//       return 'Update'
//     case 'DELETE':
//       return 'Delete'
//     default:
//       return 'Unknown'
//   }
// }

export const eventMethodSvg = method => {
  switch (method) {
    case 'POST':
      return svgAdd
    case 'PUT':
      return svgEdit
    case 'DELETE':
      return svgDelete
    default:
      return svgUnknown
  }
}

export const eventMethodCSS = method => {
  switch (method) {
    case 'POST':
      return 'vis-event-post'
    case 'PUT':
      return 'vis-event-put'
    case 'DELETE':
      return 'vis-event-delete'
    default:
      return 'vis-hor-event'
  }
}

const svgSource = svg => 'data:image/svg+xml;base64,' + window.btoa(svg)

export const eventContentCard = (title, svg) => {
  let imgSrc = svgSource(svg)
  return `
    <div>
    <img width="15px" height="15px" src=${imgSrc} />
      <span>${title}</span>
    </div>
    `
}

export const mapEventTopic = (events, group) => {
  if (!events) {
    return []
  }

  let items = events.map(event => {
    const svg = eventMethodSvg(event.method)
    const css = eventMethodCSS(event.method)
    const content = eventContentCard(event.name, svg)

    return {
      id: `topic-${event.id}`,
      start: event.occurredAt,
      content: content,
      type: 'box',
      selectable: true,
      className: css,
      group: group
    }
  })

  return items
}

export const mapEventAnalytic = (events, group) => {
  if (!events) {
    return []
  }

  let items = events.map(event => {
    const svg = eventMethodSvg(event.method)
    const css = eventMethodCSS(event.method)
    const content = eventContentCard(event.name, svg)

    return {
      id: `analytic-${event.id}`,
      start: event.occurredAt,
      content: content,
      type: 'box',
      selectable: true,
      className: css,
      group: group
    }
  })

  return items
}

export const EventDetails = ({ eventID, events }) => {
  const event = useMemo(() => events.find(topic => topic.id === eventID) || {}, [eventID, events])

  const type = useMemo(
    () => {
      switch (event.method) {
        case 'POST':
          return 'Create'
        case 'PUT':
          return 'Update'
        case 'DELETE':
          return 'Delete'
        default:
          return ''
      }
    },
    [event]
  )

  const occurredAt = useMemo(() => moment(event.occurredAt).format('LLLL'), [event])

  return (
    <Card title="selected event">
      <table className="table table-borderless1">
        <tbody>
          <tr>
            <td>Type</td>
            <td>
              {type}
            </td>
          </tr>
          <tr>
            <td>Occured At</td>
            <td>
              {occurredAt}
            </td>
          </tr>
          <tr>
            <td>Event</td>
            <td>
              {event.name}
            </td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              {event.description}
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  )
}
