import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconOrganization } from '../../../../../shared/icon/icon'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListOrganization } from '../../../../../api-new/state/content'
import { useRoleSysOrganization } from '../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'

export function OrganizationList() {
  const apiListOrganization = useApiListOrganization()

  const roleSysOrganization = useRoleSysOrganization()

  const handleReady = async () => await apiListOrganization.get()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add organization" to="/admin/organization/list/add" show={roleSysOrganization.accessCreate} />
      <MenuRecycle to="/admin/organization/list/recycle" show={roleSysOrganization.accessDelete} />

      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/admin/organization/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconOrganization} text="ORGANIZATIONS">
        <CardFullHeightScrollY title="organization" HeaderMenu={HeaderMenu}>
          <TableAgClient ref={tableRef} apiList={apiListOrganization} hideFloatingFilter onReady={handleReady}>
            <ColumnText field="name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/organization/list/edit/${row.id}`} show={roleSysOrganization.accessUpdate} />
                  <MenuDelete to={`/admin/organization/list/edit/${row.id}/delete`} show={roleSysOrganization.accessDelete} />
                  <MenuActivityLog to={`/admin/organization/list/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
