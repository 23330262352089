import React from 'react'
import { useApiFormAuthProvider, useApiListAuthProviderLink } from '../../../../../api-new/state/auth'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconImpersonate, IconProvider } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuItem } from '../../../../../shared/menu/menu'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiFormLogin } from '../../../../../api-new/state/content'
import { useLoad } from '../../../../../shared/hooks/load'
import { useAuthContext } from '../../../../../context/auth.context'
import { ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import {} from '../../../../../shared/table-builder/table-builder'

export const LoginImpersonateSSO = () => {
  const authContent = useAuthContext()

  const { loginID } = useParamsInt()

  const apiFormLogin = useApiFormLogin()
  const apiListAuthProviderLink = useApiListAuthProviderLink()
  const apiFormAuthProvider = useApiFormAuthProvider()

  const getProviders = email => {
    if (email) {
      let domain = email.split('@')[1]
      apiListAuthProviderLink.get(domain)
    }
  }

  useLoad(
    async () => {
      let login = await apiFormLogin.get(loginID)
      getProviders(login.email)
    },
    [loginID]
  )

  const handleReady = () => {
    if (apiFormLogin.form.email) {
      getProviders(apiFormLogin.form.email)
    }
  }

  const handleImpersonate = async provider => {
    let passiveToken = await apiFormAuthProvider.post()

    let redirectSigninURL = `${process.env.REACT_APP_SITE}/open/auth`
    let redirectCancelURL = `${process.env.REACT_APP_SITE}/auth/email`
    let redirectErrorURL = `${process.env.REACT_APP_SITE}/auth/error`

    let accessToken = authContent.state.accessToken

    debugger
    let url = `${process.env
      .REACT_APP_API_AUTH}/api/v1/auth-provider/${provider.identifier}/impersonate?token=${passiveToken.token}&redirect-signin-url=${redirectSigninURL}&redirect-cancel-url=${redirectCancelURL}&redirect-error-url=${redirectErrorURL}&access-token=${accessToken}&loginID=${loginID}`

    window.open(url, '_blank', 'noopener, noreferrer')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconProvider} text="impersonate SSO">
        <CardFullHeightScrollY title="impersonate SSO">
          <TableAgClient apiList={apiListAuthProviderLink} onReady={handleReady}>
            <ColumnText field="displayName" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem Icon={IconImpersonate} text="Impersonate" onClick={() => handleImpersonate(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
