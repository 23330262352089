import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Form, FormHiddenText, FormSubmitCancel, FormText, FormTextArea } from '../../../../../shared/form/form'
import { Card } from '../../../../../shared/card/card'
import { useApiFormAnalytic, useApiListAnalyticTopic, useApiListAnalyticType } from '../../../../../api-new/state/analytics'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useLoad } from '../../../../../shared/hooks/load'
import { FormSelectObj } from '../../../../../shared/form/form-select-obj'

export const AnalyticCourseAnalyticEdit = () => {
  const { nodeID, id } = useParamsInt()

  const history = useHistory()

  const apiFormAnalytic = useApiFormAnalytic()
  const apiListAnalyticTopic = useApiListAnalyticTopic()
  const apiListAnalyticType = useApiListAnalyticType()

  useLoad(() => {
    apiListAnalyticTopic.get(`${nodeID}/node`)
    apiListAnalyticType.get()
  }, [])

  const handleMatch = async () => apiFormAnalytic.get(id)

  const handleSubmit = async data => {
    await apiFormAnalytic.put(data.id, data)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="Edit" onMatch={handleMatch}>
        <CardFullHeightScrollY title="Edit Analytic">
          <Card>
            <Form state={apiFormAnalytic.form} onSubmit={handleSubmit} onRefresh={handleMatch}>
              <FormHiddenText name="parentID" value={nodeID} />

              <FormSelectObj
                label="topic"
                state={apiListAnalyticTopic}
                name="analyticTopicID"
                itemName="name"
                itemValue="id"
                valueAsNumber
                required
                small
                autoFocus
              />
              <FormSelectObj label="type" state={apiListAnalyticType} name="analyticTypeID" itemName="name" itemValue="id" valueAsNumber required small />
              <FormText className="col-12" label="name" name="name" required small />
              <FormTextArea className="col-12" label="description" name="description" small />
              <FormSubmitCancel small onCancel={handleClose} />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
