import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useApiListAnalyticParent } from '../../../../api-new/state/analytics'
import { Menu, MenuAdd, MenuDelete, MenuEdit, MenuRecycle } from '../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'

export const AnalyticParentList = () => {
  const { url } = useRouteMatch()

  const apiListAnalyticParent = useApiListAnalyticParent()

  const handleReady = () => apiListAnalyticParent.get()

  const handleDelete = async data => {
    await apiListAnalyticParent.delete(data.id)
    await apiListAnalyticParent.get()
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd to={`${url}/add`} show />
      <MenuRecycle to={`${url}/recycle`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Parents">
        <CardFullHeightScrollY title="Analytic Parents" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAnalyticParent} onReady={handleReady}>
            <ColumnText field="analyticParentType" header="Parent Type" />
            <ColumnText field="name" />
            <ColumnText field="description" />
            <ColumnText field="resource" />
            <ColumnText field="analyticTopicCount" header="Analytic Topics" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`${url}/${row.id}/edit`} show />
                  <MenuDelete onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
