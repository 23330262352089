import { useMemo, useState } from 'react'
import { useApiListGroupUser, useApiListUserLogin } from '../../api-new/state/content'
import { ModalDialog } from '../component/modal-dialog'
import { TextFilter } from './text-filter'
import { IconMinus, IconPlus } from '../icon/icon'
import { ButtonPrimary } from '../component/button'

export const PermissionGroupUserForGroupModal = ({ target, groupID, onClose, onClosed }) => {
  const [filter, updateFilter] = useState('')

  const apiListUserLogin = useApiListUserLogin()
  const apiListGroupUser = useApiListGroupUser()

  const filteredItems = useMemo(
    () => {
      let lowerFilter = filter.toLowerCase()
      return apiListUserLogin.items.filter(i => i.email.toLowerCase().includes(lowerFilter))
    },
    [apiListUserLogin.items, filter]
  )

  const handleOpen = () => {
    apiListUserLogin.get()
    apiListGroupUser.get(`${groupID}/group`)
  }
  const handleClose = () => onClose && onClose()

  const handleAdd = async userID => {
    await apiListGroupUser.post({ groupID: groupID, userID: userID })
    await apiListGroupUser.get(`${groupID}/group`)
  }

  const handleRemove = async userID => {
    await apiListGroupUser.delete(`${groupID}/${userID}`)
    await apiListGroupUser.get(`${groupID}/group`)
  }

  return (
    <ModalDialog target={target} header="Add User" fullScreen onOpen={handleOpen} onClose={onClosed}>
      <div className="d-flex flex-column h-100">
        <div>
          <TextFilter value={filter} onChange={updateFilter} />
        </div>
        <div className="flex-fill overflow-auto p-2">
          {filteredItems.map((userLogin, i) =>
            <SelectItem key={i} userLogin={userLogin} selectedGroupUsers={apiListGroupUser.items} onAdd={handleAdd} onRemove={handleRemove} />
          )}
        </div>
        <div>
          <ButtonPrimary onClick={handleClose}>close</ButtonPrimary>
        </div>
      </div>
    </ModalDialog>
  )
}

const SelectItem = ({ userLogin, selectedGroupUsers, onAdd, onRemove }) => {
  const isSelected = useMemo(
    () => {
      return selectedGroupUsers.findIndex(selectedGroupUser => selectedGroupUser.userID === userLogin.userID) !== -1
    },
    [selectedGroupUsers, userLogin]
  )

  const handleClick = () => {
    if (isSelected) {
      onRemove && onRemove(userLogin.userID)
    } else {
      onAdd && onAdd(userLogin.userID)
    }
  }

  return (
    <div className="p-1 border-bottom" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <span className="px-1">
        {isSelected ? <IconMinus /> : <IconPlus />}
      </span>
      <span>
        {userLogin.email}
      </span>
    </div>
  )
}
