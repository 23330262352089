import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { useApiListLogEventOrg } from '../../../api-new/state/analytics'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../card/card-full-height-scroll-y'
import { useParamsInt } from '../../hooks/location'
import { IconActivityLog } from '../../icon/icon'
import { Menu, MenuItem } from '../../menu/menu'
import { LogEventDetails } from './log-event-details'
import { TableAgClient } from '../../table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnText } from '../../table-builder/table-columns'

export const LogEventOrg = ({ subject }) => {
  const { id } = useParamsInt()

  const { url } = useRouteMatch()

  const apiListLogEventOrg = useApiListLogEventOrg()

  const handleReady = () => {
    if (id) apiListLogEventOrg.get(`${subject}/subject/${id}`)
    else apiListLogEventOrg.get(`${subject}/subject`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconActivityLog} text="activity log">
        <CardFullHeightScrollY title="Activity Log">
          <TableAgClient apiList={apiListLogEventOrg} onReady={handleReady}>
            <ColumnDateTime field="createdAt" header="Occured" />
            <ColumnText field="target" />
            <ColumnText field="email" />
            <ColumnText field="method" />
            <ColumnText field="ipAddress" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="details" to={`${url}/${row.id}/details`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/:logEventID/details`}>
        <LogEventDetails subject={subject} />
      </Route>
    </React.Fragment>
  )
}

export const LogEventOrgSubjectID = ({ subject, subjectID }) => {
  const { url } = useRouteMatch()

  const apiListLogEventOrg = useApiListLogEventOrg()

  const handleReady = () => {
    if (subjectID) apiListLogEventOrg.get(`${subject}/subject/${subjectID}`)
    else apiListLogEventOrg.get(`${subject}/subject`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconActivityLog} text="activity log">
        <CardFullHeightScrollY title="Activity Log">
          <TableAgClient apiList={apiListLogEventOrg} onReady={handleReady}>
            <ColumnDateTime field="createdAt" header="Occured" />
            <ColumnText field="target" />
            <ColumnText field="email" />
            <ColumnText field="action" />
            <ColumnText field="ipAddress" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="details" to={`${url}/${row.id}/details`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/:logEventID/details`}>
        <LogEventDetails />
      </Route>
    </React.Fragment>
  )
}
