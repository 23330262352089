import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../../shared/icon/icon'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useApiListApp } from '../../../../../../api-new/state/config'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'

export const AppRecycle = () => {
  const apiListApp = useApiListApp()

  const roleSysApp = useRoleSysApp()

  const handleReady = async () => await apiListApp.get('deleted')

  const handleRestore = async id => {
    await apiListApp.put(`${id}/restore`)
    await apiListApp.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListApp} onReady={handleReady}>
            <ColumnText field="name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={roleSysApp.accessDelete} />
                  <MenuPurge to={`/admin/app/recycle/purge/${row.id}`} show={roleSysApp.accessPurge} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
