import React, { useMemo } from 'react'
import { ROLE_ORG } from '../role-const'
import { useAuthContext } from '../../context/auth.context'
import { useRoleContext } from '../role.context'

export const RoleOrg = ({ children, type, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  const authContext = useAuthContext()
  const roleContext = useRoleContext()

  let display = useMemo(
    () => {
      if (authContext.state.isSysRoot) {
        return true
      }

      if (authContext.state.isSysAdmin) {
        return true
      }

      if (!roleContext.orgRoles[type]) {
        return false
      }

      if (accessRead) return roleContext.orgRoles[type].accessRead
      else if (accessCreate) return roleContext.orgRoles[type].accessCreate
      else if (accessUpdate) return roleContext.orgRoles[type].accessUpdate
      else if (accessDelete) return roleContext.orgRoles[type].accessDelete
      else return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state, roleContext.orgRoles]
  )

  return (
    <React.Fragment>
      {display && children}
    </React.Fragment>
  )
}

export const RoleOrgDashboard = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.DASHBOARD} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}

export const RoleOrgAnalytic = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.ANALYTIC} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}

export const RoleOrgNode = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.NODE} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}

export const RoleOrgUser = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.USER} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}

export const RoleOrgGroup = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.GROUP} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}

export const RoleOrgTradeshow = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.TRADESHOW} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}

export const RoleOrgAssessmentReport = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.ASSESSMENT_REPORT} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}

export const RoleOrgHeatmap = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.HEATMAP} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}

export const RoleOrgDOC = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleOrg type={ROLE_ORG.DOC} accessRead={accessRead} accessCreate={accessCreate} accessUpdate={accessUpdate} accessDelete={accessDelete}>
      {children}
    </RoleOrg>
  )
}
