import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormDefaultValue } from './hooks/input-hooks'

export const FormTextConfirm = ({ label, placeholder, subtext, name, required, defaultValue, confirmValue, shouldDirty, autoFocus }) => {
  const { register, watch, setError, clearErrors } = useFormContext()

  const formDisabled = useFormDisable()

  let watchValue = watch(name)

  useEffect(
    () => {
      if (required && !watchValue) {
        setError(name, { message: 'required' })
      } else if (watchValue.trim().toLowerCase() !== confirmValue.toLowerCase()) {
        setError(name, { message: 'mismatch' })
      } else {
        clearErrors(name)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchValue, confirmValue]
  )

  useFormDefaultValue(name, defaultValue, shouldDirty)

  return (
    <React.Fragment>
      <FormatLabel name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
        <input className="form-control" placeholder={placeholder} autoFocus={autoFocus} type="text" {...register(name, { required })} disabled={formDisabled} />
      </FormatLabel>
    </React.Fragment>
  )
}
