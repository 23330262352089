import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListLogin } from '../../../../../api-new/state/content'
import { useRoleSysLogin } from '../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'

export function LoginRecycle() {
  const apiListLogin = useApiListLogin()

  const roleSysLogin = useRoleSysLogin()

  const handleReady = async () => await apiListLogin.get('deleted')

  const handleRestore = async item => {
    await apiListLogin.put(`${item.id}/restore`)
    await apiListLogin.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListLogin} onReady={handleReady}>
            <ColumnText field="email" />
            <ColumnText field="firstName" />
            <ColumnText field="lastName" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={roleSysLogin.accessDelete} />
                  <MenuPurge to={`/admin/login/list/recycle/purge/${row.id}`} show={roleSysLogin.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
