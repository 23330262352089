import React from 'react'
import { Route, useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormNodeOrg } from '../../../../../../api-new/state/content'
import { IconList } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { useDataContext } from '../../data-context'
import { Purge } from '../../../../../../shared/page/page-purge'
import { useRoleOrgNode } from '../../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../../shared/table-builder/table-columns'

export const NodeRecycle = ({ nodeID, useApiList }) => {
  const { url } = useRouteMatch()

  const apiList = useApiList()

  const apiFormNodeOrg = useApiFormNodeOrg()

  const dataContext = useDataContext()

  const roleOrgNode = useRoleOrgNode()

  const handleRestore = async id => {
    await apiFormNodeOrg.put(`${id}/restore`)
    let node = await apiFormNodeOrg.get(id)
    dataContext.nodeInsert(node)
    if (nodeID) await apiList.get(`${nodeID}/deleted`)
    else await apiList.get('deleted')
  }

  const handleMatch = async () => {
    if (nodeID) await apiList.get(`${nodeID}/deleted`)
    else await apiList.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin" onMatch={handleMatch}>
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiList}>
            <ColumnText field="name" />
            <ColumnText field="path" />
            <ColumnText field="datatype" header="Type" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={roleOrgNode.accessDelete} />
                  <MenuPurge to={`${url}/${row.id}/purge`} show={roleOrgNode.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/:purgeID/purge`}>
        <Purge useApiForm={useApiFormNodeOrg} titleParam="name" successPath={url} />
      </Route>
    </React.Fragment>
  )
}
