import { useApiListResultAssessmentUser } from '../../../../api-new/state/assessment'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Menu, MenuExportCsv, MenuExportExcel, MenuItem, MenuRecycle } from '../../../../shared/menu/menu'
import { IconView } from '../../../../shared/icon/icon'
import { useRouteMatch } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { IconList } from '../../../../shared/icon/icon'
import { useRef } from 'react'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'

export const AssessmentUserSummary = () => {
  const { url } = useRouteMatch()
  const { userID } = useParamsInt()

  const apiListTestResultAssessmentUser = useApiListResultAssessmentUser()

  const handleReady = () => {
    apiListTestResultAssessmentUser.get(`reports/${userID}/user`)
  }

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuRecycle to={`${url}/recycle`} show />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <BreadcrumbView Icon={IconList} text="Assessment Summary">
      <CardFullHeightScrollY title="assessment reports" HeaderMenu={HeaderMenu}>
        <TableAgClient ref={tableRef} apiList={apiListTestResultAssessmentUser} onReady={handleReady}>
          <ColumnText field="course" />
          <ColumnText field="title" />
          <ColumnText field="attempts" />
          <ColumnText field="passed" />
          <ColumnText field="avgDuration" />
          <ColumnMenu
            menu={row =>
              <Menu small>
                <MenuItem text="view" Icon={IconView} to={`${url}/view/${row.assessmentID}`} show />
                <MenuItem text="attempts" Icon={IconView} to={`${url}/${row.assessmentID}/assessment`} show pin />
              </Menu>}
          />
        </TableAgClient>
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
