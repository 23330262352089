import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { IconList } from '../../../../../../shared/icon/icon'
import { useApiListLogEvent } from '../../../../../../api-new/state/content'
import { ColumnDateTime, ColumnMenu, ColumnText } from '../../../../../../shared/table-builder/table-columns'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'

export function LogEventRecycle() {
  const apiListLogEvent = useApiListLogEvent()

  const handleReady = async () => await apiListLogEvent.get('deleted')

  const handleRestore = async item => {
    await apiListLogEvent.put(`${item.id}/restore`)
    await apiListLogEvent.get('deleted')
  }

  const handlePurge = async item => {
    await apiListLogEvent.delete(`${item.id}/purge`)
    await apiListLogEvent.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListLogEvent} onReady={handleReady}>
            <ColumnDateTime field="createdAt" header="Occured" />
            <ColumnText field="subject" />
            <ColumnText field="path" />
            <ColumnText field="method" />
            <ColumnText field="action" />
            <ColumnText field="ipAddress" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show />
                  <MenuPurge onClick={() => handlePurge(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
