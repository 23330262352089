import { forwardRef, useMemo, useRef, useState } from 'react'
import { Search } from '../component/filter'
import { TableAG } from './table-ag'
import { getColumns } from './table-columns'

export const TableAgInfinite = forwardRef(({ children, showAdvance, onGetRows, onRowClicked }, ref) => {
  const tableRef = useRef()

  let columns = useMemo(() => getColumns(children), [children])

  const [search, updateSearch] = useState('')
  const [loading, updateLoading] = useState(true)

  const handleReady = async agGrid => {
    let datasource = {
      getRows: async params => {
        let sortCol = ''
        let sortDir = ''

        if (params.sortModel.length) {
          sortCol = params.sortModel[0].colId
          sortDir = params.sortModel[0].sort
        }

        let search = (params.context && params.context.search) || ''

        if (onGetRows) {
          updateLoading(true)
          let result = await onGetRows(params.startRow, params.endRow, search, sortCol, sortDir)
          params.successCallback(result.data, result.count)
          updateLoading(false)
        }
      }
    }

    agGrid.api.setGridOption('datasource', datasource)
  }

  const handleSearch = search => {
    tableRef.current.api.setGridOption('context', { search: search })
    tableRef.current.api.purgeInfiniteCache()
    tableRef.current.api.paginationGoToPage(0)
  }

  const handleRefresh = () => {
    tableRef.current.api.purgeInfiniteCache()
    tableRef.current.api.paginationGoToPage(0)
  }

  return (
    <div className="d-flex flex-column h-100">
      <div>
        <Search value={search} onChange={updateSearch} onSearch={handleSearch} />
      </div>
      <div className="flex-fill">
        <TableAG
          ref={tableRef}
          funcRef={ref}
          columns={columns}
          loading={loading}
          rowModelType="infinite"
          cacheBlockSize={1000}
          maxBlocksInCache={10}
          cacheOverflowSize={10}
          infiniteInitialRowCount={10}
          showAdvance={showAdvance}
          onReady={handleReady}
          onRefresh={handleRefresh}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  )
})
