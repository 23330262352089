import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Timeline } from 'vis-timeline/peer'
import moment from 'moment'
import 'vis-timeline/styles/vis-timeline-graph2d.css'
import { useLoad } from '../../../../../shared/hooks/load'

const defaultOptions = {
  horizontalScroll: true,
  verticalScroll: true,
  zoomKey: 'ctrlKey'
  // style: 'color: red; background-color: green;'
}

export const VisTimeline = forwardRef(({ items, groups, height, onSelect }, ref) => {
  const refVis = useRef()

  const [timeline, updateTimeline] = useState(null)

  useImperativeHandle(ref, () => ({
    setRange: (start, end) => {
      if (start && end) {
        const startDate = moment(start).subtract(1, 'W')._d
        const endDate = moment(end).add(1, 'W')._d
        timeline && timeline.setWindow(startDate, endDate)
      }
    },
    setNow: () => timeline && timeline.moveTo(moment()._d),
    zoomIn: () => timeline && timeline.zoomIn(1),
    zoomOut: () => timeline && timeline.zoomOut(1),
    redraw: () => timeline && timeline.redraw()
  }))

  const [selected, updateSelected] = useState([])

  useLoad(() => onSelect && onSelect(selected), [selected])

  useEffect(() => {
    const options = { ...defaultOptions, minHeight: height, maxHeight: height }
    const timeline = new Timeline(refVis.current, [], options)
    updateTimeline(timeline)
    timeline.on('select', properties => {
      updateSelected(properties.items)
    })

    return () => timeline.destroy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLoad(() => timeline && timeline.setOptions({ ...defaultOptions, minHeight: height, maxHeight: height }), [timeline, height])
  useLoad(() => timeline && timeline.setItems(items), [timeline, items])
  useLoad(() => timeline && timeline.setGroups(groups), [timeline, groups])

  return <div ref={refVis} className="h-100" />
})
