import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router'
import Detail from './detail'
import { Graph } from './graph'
import Number from './number'
import { Selector } from './selector'
import { Table } from './table'
import moment from 'moment'

import { useCourseDetailForm, useCourseStepList, useCourseUserList, useCourseSessionCountList } from '../state'
import { SessionDetails } from './session-details'
import { useAnalyticReportList } from '../../../../../api/state'
import { exportCsv } from '../../../../../shared/export/utils'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { ExportModel } from '../../../../../shared/export/export'
import { IconAnalytic } from '../../../../../shared/icon/icon'

const useQuery = () => {
  let location = useLocation()
  let search = location.search
  let query = new URLSearchParams(search)
  return query
}

export const AnalyticView = () => {
  const { nodeID } = useParams()
  const query = useQuery()

  const detailsRef = useRef(null)

  let userID = query.get('user')
  let device = query.get('device')
  let sessionID = query.get('session')

  let end = moment.utc().format('YYYY-MM-DD')
  let start = moment().subtract(2, 'weeks').format('YYYY-MM-DD')

  const [courseSessionCountListActions, courseSessionCountListState] = useCourseSessionCountList()

  useEffect(() => {
    courseSessionCountListActions.get(`${nodeID}/${start}/${end}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [courseDetailFormAction, courseDetailFormState] = useCourseDetailForm()
  const [courseStepListActions, courseStepListState] = useCourseStepList()
  const [courseUserListActions, courseUserListState] = useCourseUserList()

  useEffect(
    () => {
      courseDetailFormAction.get(nodeID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  useEffect(
    () => {
      courseUserListActions.get(nodeID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  useEffect(
    () => {
      if (userID && userID !== 'all') {
        courseStepListActions.get(`${nodeID}/${userID}`)
      } else {
        courseStepListActions.get(`${nodeID}`)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID, userID]
  )

  let deviceSteps = useMemo(
    () => {
      if (device !== null && device !== 'all') {
        if (!courseStepListState.items) {
          // debugger
        }

        return courseStepListState.items.filter(step => step.deviceType === device)
      } else {
        return courseStepListState.items
      }
    },
    [device, courseStepListState.items]
  )

  let devices = useMemo(() => [...new Set(courseStepListState.items.map(i => i.deviceType))], [courseStepListState.items])

  const handleShowDetails = () => detailsRef.current.openDetails()

  ///////////////////////////////////
  const exportRef = useRef()

  const [analyticReportActions] = useAnalyticReportList()

  const handleExportGet = async () => {
    let result = await analyticReportActions.get('export')
    exportRef.current.show(result)
  }

  const handleExportPost = async (filename, headers) => {
    headers = headers.join(',')

    let nodeID = courseDetailFormState.form.nodeID

    if (sessionID) {
      let result = await analyticReportActions.postArgs({ path: `export/${nodeID}/session/${sessionID}`, data: headers })
      exportCsv(result, filename)
    } else if (userID) {
      if (device) {
        let result = await analyticReportActions.postArgs({ path: `export/${nodeID}/user/${userID}/device/${device}`, data: headers })
        exportCsv(result, filename)
      } else {
        let result = await analyticReportActions.postArgs({ path: `export/${nodeID}/user/${userID}`, data: headers })
        exportCsv(result, filename)
      }
    } else {
      let result = await analyticReportActions.postArgs({ path: `export/${nodeID}`, data: headers })
      exportCsv(result, filename)
    }

    exportRef.current.hide()
  }
  ///////////////////////////////////

  const handleReady = () => courseSessionCountListActions.get(`${nodeID}/${start}/${end}`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalytic} text="analytics">
        <ExportModel ref={exportRef} name="analytics" onExport={handleExportPost} />

        <div className="d-flex flex-column h-100">
          <div>
            <Detail details={courseDetailFormState.form} />
          </div>
          <div>
            {(userID === null || userID === 'all') && <Graph nodeID={nodeID} sessionCount={courseSessionCountListState.items} />}
          </div>
          <div>
            <Number sessionID={sessionID} details={courseDetailFormState.form} deviceSteps={deviceSteps} />
          </div>
          <div>
            <Selector
              nodeID={nodeID}
              userID={userID}
              device={device}
              sessionID={sessionID}
              users={courseUserListState.items}
              steps={courseStepListState.items}
              devices={devices}
              deviceSteps={deviceSteps}
            />
          </div>
          <div>
            <SessionDetails ref={detailsRef} sessionID={sessionID} />
          </div>
          <div className="flex-fill" style={{ minHeight: 0 }}>
            <Table sessionID={sessionID} items={deviceSteps} onShowDetails={handleShowDetails} onExport={handleExportGet} onReady={handleReady} />
          </div>
        </div>
      </BreadcrumbView>
    </React.Fragment>
  )
}
