import React from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
import { useRouteMatch } from 'react-router-dom'
import { Menu, MenuPurge, MenuRestore } from '../../../../shared/menu/menu'
import { useParamsInt } from '../../../../shared/hooks/location'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { useApiListManageAssessment } from '../../../../api-new/state/assessment'
import { useRoleOrgAssessmentManage } from '../../../../role/hook/role-org'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'

export function AssessmentManageRecycle() {
  const { nodeID } = useParamsInt()

  const { url } = useRouteMatch()

  const apiListManageAssessment = useApiListManageAssessment()

  const roleOrgAssessmentManage = useRoleOrgAssessmentManage()

  const handleMatch = async () => await apiListManageAssessment.get(`deleted?nodeID=${nodeID}`)

  const handleRestore = async id => {
    await apiListManageAssessment.put(`${id}/restore`)
    await apiListManageAssessment.get(`deleted?nodeID=${nodeID}`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin" onMatch={handleMatch}>
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListManageAssessment}>
            <ColumnText field="title" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={roleOrgAssessmentManage.accessDelete} />
                  <MenuPurge to={`${url}/purge/${row.id}`} show={roleOrgAssessmentManage.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
