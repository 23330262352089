import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faPlus,
  faMinus,
  faEdit,
  faInfoSquare,
  faTrash,
  faRecycle,
  faShredder,
  faTimes,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faExternalLink,
  faEye,
  faCopy,
  faCheck,
  faSquareCheck,
  faBookAlt,
  faFile,
  faTheaterMasks,
  faSync,
  faShareSquare,
  faFileExport,
  faUpload,
  faBan,
  faCog,
  faChartPie,
  faChartMixed,
  faUser,
  faUsers,
  faFolder,
  faDesktop,
  faClock,
  faClipboard,
  faHeat,
  faChartLine,
  faCalendar,
  faCalendars,
  faCalendarDays,
  faCaretDown,
  faCaretUp,
  faPalette,
  faRedo,
  faTable,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMagnifyingGlass,
  faBars,
  faFileCsv,
  faFileExcel,
  faGear,
  faStore,
  faFileAlt,
  faLayerGroup,
  faSun,
  faMoon,
  faCircleHalfStroke,
  faKey,
  faLink,
  faArrowAltCircleRight,
  faGripLines,
  faBuilding,
  faSignOutAlt,
  faArrowRight,
  faList,
  faPaperPlane,
  faVideo,
  faBooks,
  faLevelUp,
  faLevelDown,
  faDownload,
  faBold,
  faUnderline,
  faItalic,
  faStrikethrough,
  faIndent,
  faOutdent,
  faListUl,
  faListOl,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faWavePulse,
  faPipe,
  faCompress,
  faExpand,
  faLaptopMobile,
  faRightToBracket,
  faInputNumeric,
  faInputText,
  faFileVector,
  faAngleRight,
  faPanorama,
  faGrid,
  faPlay,
  faArrowUpRightFromSquare,
  faSquare,
  faLock,
  faRobot
} from '@fortawesome/pro-solid-svg-icons'

import { faChartNetwork, faCopyright } from '@fortawesome/pro-regular-svg-icons'

// sizes: sm, lg, 2x, 3x, 4x, 5x, 6x, 7x, 8x, 9x

const Icon = ({ className, icon, size, transform }) =>
  <span className={className}>
    <FontAwesomeIcon className="mx-1" icon={icon} size={size} transform={transform} />
  </span>

export const IconAiDocument = ({ className, size, transform }) => <Icon className={className} icon={faRobot} size={size} transform={transform} />

export const IconSelectListAdd = ({ className, size, transform }) => <Icon className={className} icon={faCheck} size={size} transform={transform} />

export const IconSelectListRemove = ({ className, size, transform }) => <Icon className={className} icon={faMinus} size={size} transform={transform} />

export const IconSelectListReadonly = ({ className, size, transform }) => <Icon className={className} icon={faLock} size={size} transform={transform} />

export const IconReadonly = ({ className, size, transform }) => <Icon className={className} icon={faSquare} size={size} transform={transform} />

export const IconInvite = ({ className, size, transform }) => <Icon className={className} icon={faPaperPlane} size={size} transform={transform} />

export const IconPermission = ({ className, size, transform }) => <Icon className={className} icon={faKey} size={size} transform={transform} />

export const IconDeepLinkOpen = ({ className, size, transform }) =>
  <Icon className={className} icon={faArrowUpRightFromSquare} size={size} transform={transform} />

export const IconCopyright = ({ className, size, transform }) => <Icon className={className} icon={faCopyright} size={size} transform={transform} />

export const IconBreadcrumbSeperator = ({ className, size, transform }) => <Icon className={className} icon={faAngleRight} size={size} transform={transform} />

export const IconLibrary = ({ className, size, transform }) => <Icon className={className} icon={faBooks} size={size} transform={transform} />

export const IconLoginPin = ({ className, size, transform }) => <Icon className={className} icon={faInputNumeric} size={size} transform={transform} />
export const IconLoginEmail = ({ className, size, transform }) => <Icon className={className} icon={faInputText} size={size} transform={transform} />
export const IconLoginDevice = ({ className, size, transform }) => <Icon className={className} icon={faRightToBracket} size={size} transform={transform} />
export const IconPipe = ({ className, size, transform }) => <Icon className={className} icon={faPipe} size={size} transform={transform} />
export const IconActivityLog = ({ className, size, transform }) => <Icon className={className} icon={faWavePulse} size={size} transform={transform} />
export const IconApp = ({ className, size, transform }) => <Icon className={className} icon={faStore} size={size} transform={transform} />

export const IconSettings = ({ className, size, transform }) => <Icon className={className} icon={faGear} size={size} transform={transform} />
export const IconAdd = ({ className, size, transform }) => <Icon className={className} icon={faPlus} size={size} transform={transform} />
export const IconEdit = ({ className, size, transform }) => <Icon className={className} icon={faEdit} size={size} transform={transform} />
export const IconDetails = ({ className, size, transform }) => <Icon className={className} icon={faInfoSquare} size={size} transform={transform} />
export const IconDelete = ({ className, size, transform }) => <Icon className={className} icon={faTrash} size={size} transform={transform} />
export const IconRecycle = ({ className, size, transform }) => <Icon className={className} icon={faRecycle} size={size} transform={transform} />
export const IconRestore = ({ className, size, transform }) => <Icon className={className} icon={faRecycle} size={size} transform={transform} />
export const IconPurge = ({ className, size, transform }) => <Icon className={className} icon={faShredder} size={size} transform={transform} />
export const IconExport = ({ className, size, transform }) => <Icon className={className} icon={faFileExport} size={size} transform={transform} />
export const IconCsv = ({ className, size, transform }) => <Icon className={className} icon={faFileCsv} size={size} transform={transform} />
export const IconExcel = ({ className, size, transform }) => <Icon className={className} icon={faFileExcel} size={size} transform={transform} />
export const IconMenu = ({ className, size, transform }) => <Icon className={className} icon={faEllipsisV} size={size} transform={transform} />
export const IconTableFill = ({ className, size, transform }) => <Icon className={className} icon={faExpand} size={size} transform={transform} />
export const IconTableFit = ({ className, size, transform }) => <Icon className={className} icon={faCompress} size={size} transform={transform} />
export const IconBegin = ({ className, size, transform }) => <Icon className={className} icon={faChevronDoubleLeft} size={size} transform={transform} />
export const IconEnd = ({ className, size, transform }) => <Icon className={className} icon={faChevronDoubleRight} size={size} transform={transform} />
export const IconNext = ({ className, size, transform }) => <Icon className={className} icon={faChevronRight} size={size} transform={transform} />
export const IconPrevious = ({ className, size, transform }) => <Icon className={className} icon={faChevronLeft} size={size} transform={transform} />
export const IconRefresh = ({ className, size, transform }) => <Icon className={className} icon={faRedo} size={size} transform={transform} />
export const IconEvent = ({ className, size, transform }) => <Icon className={className} icon={faCalendarDays} size={size} transform={transform} />
export const IconEnter = ({ className, size, transform }) => <Icon className={className} icon={faChevronRight} size={size} transform={transform} />
export const IconClose = ({ className, size, transform }) => <Icon className={className} icon={faTimes} size={size} transform={transform} />
export const IconCalender = ({ className, size, transform }) => <Icon className={className} icon={faCalendars} size={size} transform={transform} />
export const IconCalenders = ({ className, size, transform }) => <Icon className={className} icon={faCalendar} size={size} transform={transform} />
export const IconCheck = ({ className, size, transform }) => <Icon className={className} icon={faCheck} size={size} transform={transform} />
export const IconFile = ({ className, size, transform }) => <Icon className={className} icon={faFile} size={size} transform={transform} />
export const IconActivate = ({ className, size, transform }) => <Icon className={className} icon={faCheck} size={size} transform={transform} />
export const IconDepublish = ({ className, size, transform }) => <Icon className={className} icon={faBookAlt} size={size} transform={transform} />
export const IconUnactivate = ({ className, size, transform }) => <Icon className={className} icon={faTimes} size={size} transform={transform} />
export const IconView = ({ className, size, transform }) => <Icon className={className} icon={faEye} size={size} transform={transform} />

export const IconCaretDown = ({ className, size, transform }) => <Icon className={className} icon={faCaretDown} size={size} transform={transform} />
export const IconCaretUp = ({ className, size, transform }) => <Icon className={className} icon={faCaretUp} size={size} transform={transform} />

export const IconBigger = ({ className, size, transform }) => <Icon className={className} icon={faMagnifyingGlassPlus} size={size} transform={transform} />
export const IconSmaller = ({ className, size, transform }) => <Icon className={className} icon={faMagnifyingGlassMinus} size={size} transform={transform} />
export const IconDown = ({ className, size, transform }) => <Icon className={className} icon={faChevronDown} size={size} transform={transform} />
export const IconUp = ({ className, size, transform }) => <Icon className={className} icon={faChevronUp} size={size} transform={transform} />
export const IconUser = ({ className, size, transform }) => <Icon className={className} icon={faUser} size={size} transform={transform} />
export const IconUsers = ({ className, size, transform }) => <Icon className={className} icon={faUsers} size={size} transform={transform} />
export const IconCourse = ({ className, size, transform }) => <Icon className={className} icon={faPanorama} size={size} transform={transform} />
export const IconSession = ({ className, size, transform }) => <Icon className={className} icon={faClock} size={size} transform={transform} />
export const IconPlatform = ({ className, size, transform }) => <Icon className={className} icon={faDesktop} size={size} transform={transform} />
export const IconAssessment = ({ className, size, transform }) => <Icon className={className} icon={faClipboard} size={size} transform={transform} />
export const IconHeatmap = ({ className, size, transform }) => <Icon className={className} icon={faHeat} size={size} transform={transform} />
export const IconDashboard = ({ className, size, transform }) => <Icon className={className} icon={faChartLine} size={size} transform={transform} />
export const IconSetup = ({ className, size, transform }) => <Icon className={className} icon={faCog} size={size} transform={transform} />
export const IconAnalytic = ({ className, size, transform }) => <Icon className={className} icon={faChartPie} size={size} transform={transform} />
export const IconAnalyticGeneral = ({ className, size, transform }) => <Icon className={className} icon={faChartMixed} size={size} transform={transform} />
export const IconAnalyticCustom = ({ className, size, transform }) => <Icon className={className} icon={faChartNetwork} size={size} transform={transform} />
export const IconReload = ({ className, size, transform }) => <Icon className={className} icon={faSync} size={size} transform={transform} />
export const IconCancel = ({ className, size, transform }) => <Icon className={className} icon={faBan} size={size} transform={transform} />
export const IconTable = ({ className, size, transform }) => <Icon className={className} icon={faTable} size={size} transform={transform} />
export const IconSearch = ({ className, size, transform }) => <Icon className={className} icon={faMagnifyingGlass} size={size} transform={transform} />
export const IconLegal = ({ className, size, transform }) => <Icon className={className} icon={faFileAlt} size={size} transform={transform} />
export const IconGroup = ({ className, size, transform }) => <Icon className={className} icon={faLayerGroup} size={size} transform={transform} />
export const IconOrganization = ({ className, size, transform }) => <Icon className={className} icon={faBuilding} size={size} transform={transform} />
export const IconThemeLight = ({ className, size, transform }) => <Icon className={className} icon={faSun} size={size} transform={transform} />
export const IconThemeDark = ({ className, size, transform }) => <Icon className={className} icon={faMoon} size={size} transform={transform} />
export const IconThemeSystem = ({ className, size, transform }) => <Icon className={className} icon={faCircleHalfStroke} size={size} transform={transform} />
export const IconPassword = ({ className, size, transform }) => <Icon className={className} icon={faKey} size={size} transform={transform} />
export const IconLink = ({ className, size, transform }) => <Icon className={className} icon={faLink} size={size} transform={transform} />
export const IconLogout = ({ className, size, transform }) => <Icon className={className} icon={faSignOutAlt} size={size} transform={transform} />
export const IconList = ({ className, size, transform }) => <Icon className={className} icon={faList} size={size} transform={transform} />
export const IconContent = ({ className, size, transform }) => <Icon className={className} icon={faPanorama} size={size} transform={transform} />
export const IconPlus = ({ className, size, transform }) => <Icon className={className} icon={faPlus} size={size} transform={transform} />
export const IconMinus = ({ className, size, transform }) => <Icon className={className} icon={faMinus} size={size} transform={transform} />

export const IconMove = ({ className, size, transform }) => <Icon className={className} icon={faArrowRight} size={size} transform={transform} />
export const IconCopy = ({ className, size, transform }) => <Icon className={className} icon={faCopy} size={size} transform={transform} />
export const IconRole = ({ className, size, transform }) => <Icon className={className} icon={faKey} size={size} transform={transform} />
export const IconLogins = ({ className, size, transform }) => <Icon className={className} icon={faUsers} size={size} transform={transform} />
export const IconProvider = ({ className, size, transform }) => <Icon className={className} icon={faKey} size={size} transform={transform} />
export const IconEmail = ({ className, size, transform }) => <Icon className={className} icon={faPaperPlane} size={size} transform={transform} />
export const IconTradeShow = ({ className, size, transform }) => <Icon className={className} icon={faVideo} size={size} transform={transform} />
export const IconDocs = ({ className, size, transform }) => <Icon className={className} icon={faBooks} size={size} transform={transform} />
export const IconDownload = ({ className, size, transform }) => <Icon className={className} icon={faDownload} size={size} transform={transform} />
export const IconUpload = ({ className, size, transform }) => <Icon className={className} icon={faUpload} size={size} transform={transform} />
export const IconPublish = ({ className, size, transform }) => <Icon className={className} icon={faShareSquare} size={size} transform={transform} />
export const IconTimes = ({ className, size, transform }) => <Icon className={className} icon={faTimes} size={size} transform={transform} />
export const IconCategory = ({ className, size, transform }) => <Icon className={className} icon={faFolder} size={size} transform={transform} />
export const IconStreamingVideo = ({ className, size, transform }) => <Icon className={className} icon={faVideo} size={size} transform={transform} />
export const IconColorPallet = ({ className, size, transform }) => <Icon className={className} icon={faPalette} size={size} transform={transform} />
export const IconTemplate = ({ className, size, transform }) => <Icon className={className} icon={faFileVector} size={size} transform={transform} />
export const IconLaunch = ({ className, size, transform }) => <Icon className={className} icon={faExternalLink} size={size} transform={transform} />
export const IconBars = ({ className, size, transform }) => <Icon className={className} icon={faBars} size={size} transform={transform} />
export const IconGrid = ({ className, size, transform }) => <Icon className={className} icon={faGrid} size={size} transform={transform} />
export const IconPromote = ({ className, size, transform }) => <Icon className={className} icon={faLevelUp} size={size} transform={transform} />
export const IconDemote = ({ className, size, transform }) => <Icon className={className} icon={faLevelDown} size={size} transform={transform} />
export const IconContactUs = ({ className, size, transform }) => <Icon className={className} icon={faPaperPlane} size={size} transform={transform} />
export const IconRedo = ({ className, size, transform }) => <Icon className={className} icon={faRedo} size={size} transform={transform} />
export const IconGrip = ({ className, size, transform }) => <Icon className={className} icon={faGripLines} size={size} transform={transform} />
export const IconError = ({ className, size, transform }) => <Icon className={className} icon={faBan} size={size} transform={transform} />
export const IconImpersonate = ({ className, size, transform }) => <Icon className={className} icon={faTheaterMasks} size={size} transform={transform} />
export const IconGoto = ({ className, size, transform }) => <Icon className={className} icon={faArrowAltCircleRight} size={size} transform={transform} />
export const IconBold = ({ className, size, transform }) => <Icon className={className} icon={faBold} size={size} transform={transform} />
export const IconUnderline = ({ className, size, transform }) => <Icon className={className} icon={faUnderline} size={size} transform={transform} />
export const IconItalic = ({ className, size, transform }) => <Icon className={className} icon={faItalic} size={size} transform={transform} />
export const IconStrikeThrough = ({ className, size, transform }) => <Icon className={className} icon={faStrikethrough} size={size} transform={transform} />
export const IconIndent = ({ className, size, transform }) => <Icon className={className} icon={faIndent} size={size} transform={transform} />
export const IconOutdent = ({ className, size, transform }) => <Icon className={className} icon={faOutdent} size={size} transform={transform} />
export const IconListUL = ({ className, size, transform }) => <Icon className={className} icon={faListUl} size={size} transform={transform} />
export const IconListOL = ({ className, size, transform }) => <Icon className={className} icon={faListOl} size={size} transform={transform} />
export const IconAlignLeft = ({ className, size, transform }) => <Icon className={className} icon={faAlignLeft} size={size} transform={transform} />
export const IconAlignRight = ({ className, size, transform }) => <Icon className={className} icon={faAlignRight} size={size} transform={transform} />
export const IconAlignCenter = ({ className, size, transform }) => <Icon className={className} icon={faAlignCenter} size={size} transform={transform} />
export const IconPinConfirm = ({ className, size, transform }) => <Icon className={className} icon={faLaptopMobile} size={size} transform={transform} />
export const IconConfirm = ({ className, size, transform }) => <Icon className={className} icon={faSquareCheck} size={size} transform={transform} />

export const IconVideo = ({ className, size, transform }) => <Icon className={className} icon={faPlay} size={size} transform={transform} />

export const IconAuthLogin = ({ className, size, transform }) => <Icon className={className} icon={faKey} size={size} transform={transform} />
