import { useApiForm } from '../api-form'
import { useApiList } from '../api-list'
import { urlArgs } from '../state'

export const useApiListLogEventOrg = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/log-event-org${urlArgs(args)}`)
export const useApiFormLogEventOrg = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/ddd/log-event-org${urlArgs(args)}`)
export const useApiListLogEventSys = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/log-event-sys${urlArgs(args)}`)

export const useApiListChunkReportCustomOrg = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/report-custom-org${urlArgs(args)}`)
export const useApiListReportCustomOrg = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/report-custom-org${urlArgs(args)}`)

export const useApiListAnalytic = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic${urlArgs(args)}`)
export const useApiFormAnalytic = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic${urlArgs(args)}`)

export const useApiListAnalyticTopic = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-topic${urlArgs(args)}`)
export const useApiFormAnalyticTopic = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-topic${urlArgs(args)}`)

export const useApiListAnalyticParent = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-parent${urlArgs(args)}`)
export const useApiFormAnalyticParent = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-parent${urlArgs(args)}`)

export const useApiListAnalyticType = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-type${urlArgs(args)}`)
export const useApiFormAnalyticType = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-type${urlArgs(args)}`)

export const useApiListAnalyticParentType = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-parent-type${urlArgs(args)}`)
export const useApiFormAnalyticParentType = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-parent-type${urlArgs(args)}`)

export const useApiListAnalyticPeriod = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-period${urlArgs(args)}`)

export const useApiListAnalyticEvent = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/analytic-event${urlArgs(args)}`)

export const useApiListReportGeneralOrg = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/report-general-org${urlArgs(args)}`)

export const useApiListReportGeneralUser = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/report-general-user${urlArgs(args)}`)

export const useApiListReportGeneralCourse = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/report-general-course${urlArgs(args)}`)

export const useApiListGraphCustomCourse = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/graph-custom-course${urlArgs(args)}`)

export const useApiListGraphGeneralCourse = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/graph-general-course${urlArgs(args)}`)

export const useApiListGraphGeneralOrg = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/graph-general-org${urlArgs(args)}`)
