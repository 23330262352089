import React, { useMemo } from 'react'
import { useApiFormAnalytic } from '../../../../../api-new/state/analytics'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useHistory } from 'react-router-dom'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { Card } from '../../../../../shared/card/card'
import { Form, FormSubmitCancel } from '../../../../../shared/form/form'
import { FormTextConfirmPurge } from '../../../../../shared/form/form-text-confirm-purge'

export const AnalyticCourseAnalyticPurge = () => {
  const { nodeID, analyticID } = useParamsInt()

  const history = useHistory()

  const apiFormAnalytic = useApiFormAnalytic()

  const handleMatch = () => apiFormAnalytic.get(`${nodeID}/node/${analyticID}/deleted`)

  const handleSubmit = async data => {
    await apiFormAnalytic.delete(`${data.id}/purge`)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  const showWarning = useMemo(() => !!apiFormAnalytic.form.analyticDataCount, [apiFormAnalytic])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Purge" onMatch={handleMatch}>
        <CardFullHeightScrollY title={`Purge ${apiFormAnalytic.form.name}`}>
          {showWarning &&
            <Card>
              <span className="text-danger">
                This Analytic has {apiFormAnalytic.form.analyticDataCount} data points attached to it. Purging will remove these and associated data.
              </span>
            </Card>}
          <Form state={apiFormAnalytic.form} onSubmit={handleSubmit} onRefresh={handleMatch}>
            <Card>
              <FormTextConfirmPurge autoFocus />
              <FormSubmitCancel onCancel={handleClose} />
            </Card>
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
