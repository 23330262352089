import { useMemo, useRef } from 'react'
import { useApiListResultAssessmentCourse } from '../../../../api-new/state/assessment'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Menu, MenuDelete, MenuExportCsv, MenuExportExcel, MenuItem } from '../../../../shared/menu/menu'
import { IconView } from '../../../../shared/icon/icon'
import { useRouteMatch } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormUserLogin } from '../../../../api-new/state/content'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnDateTime, ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'

export const AssessmentCourseAttempts = () => {
  const { url } = useRouteMatch()
  const { userID, assessmentID } = useParamsInt()

  const apiFormUserLogin = useApiFormUserLogin()
  const apiListTestResultAssessmentCourse = useApiListResultAssessmentCourse()

  const handleReady = () => {
    apiFormUserLogin.get(userID)
    apiListTestResultAssessmentCourse.get(`attempts/${assessmentID}/assessment/${userID}/user`)
  }

  const title = useMemo(() => `Attempts ${apiFormUserLogin.form.email}`, [apiFormUserLogin.form])

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <BreadcrumbView text={apiFormUserLogin.form.email}>
      <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
        <TableAgClient ref={tableRef} apiList={apiListTestResultAssessmentCourse} onReady={handleReady}>
          <ColumnText field="course" sortable filter />
          <ColumnText field="title" sortable filter />
          <ColumnText field="type" sortable filter />
          <ColumnText field="passingScore" sortable filter />
          <ColumnText field="score" sortable filter />
          <ColumnText field="passed" sortable filter />
          <ColumnDateTime field="start" sortable filter />
          <ColumnDateTime field="end" sortable filter />
          <ColumnText field="duration" sortable filter />
          <ColumnMenu
            menu={row =>
              <Menu small>
                <MenuItem text="details" Icon={IconView} to={`${url}/${row.userAssessmentID}/details`} show pin />
                <MenuDelete Icon={IconView} to={`${url}/delete/${row.userAssessmentID}`} show />
              </Menu>}
          />
        </TableAgClient>
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
