import { useCallback } from "react"
import { useEffect, useState } from 'react'
import { useNotificationContext } from "../../../../context/notification/context"
import { useApiListAnalyticParent } from "../../../../api-new/state/analytics"

export const useParseJSON = () => {
    return useCallback((value) => {
        try {
            if (typeof value === 'boolean') {
                return value ? "true" : "false"
              }

              return JSON.parse(value)
        }catch {
            return value
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

}

export const useAnalyticParentCourse = () => {
  const notificationContext = useNotificationContext()

  const apiListAnalyticParent = useApiListAnalyticParent()

  const [parent, updateParent] = useState({})

  useEffect(() => {
    const parentName = 'course'
    apiListAnalyticParent.get().then(parents => {
      const parent = parents.find(i => i.name === parentName)
      if (parent) updateParent(parent)
      else notificationContext.alert.error(`analytic parent: ${parentName} not found`)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return parent
}
