import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useApiListSendgridEvent } from '../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuDetails } from '../../../../../shared/menu/menu'
import { IconEmail } from '../../../../../shared/icon/icon'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu, ColumnUnixDateTime } from '../../../../../shared/table-builder/table-columns'

export const SendgridEventList = () => {
  const apiListSendgridEvent = useApiListSendgridEvent()

  const handleReady = () => apiListSendgridEvent.get()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEmail} text="SENDGRID EVENTS">
        <CardFullHeightScrollY title="events">
          <TableAgClient apiList={apiListSendgridEvent} onReady={handleReady}>
            <ColumnUnixDateTime field="timestamp" header="Sent At" />
            <ColumnText field="email" />
            <ColumnText field="templateName" header="Template" />
            <ColumnText field="event" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDetails to={`/admin/sendgrid-event/list/details/${row.id}`} show pin />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
