import React from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useApiListAssessmentAssignment } from '../../../../api-new/state/assessment'
import { IconEdit } from '../../../../shared/icon/icon'
import { TableAgClient } from '../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../shared/table-builder/table-columns'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuAdd, MenuDelete } from '../../../../shared/menu/menu'
import { useRouteMatch } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const AssessmentManageAssignment = () => {
  const { url } = useRouteMatch()
  const { assessmentID } = useParamsInt()

  const apiListAssessmentAssignment = useApiListAssessmentAssignment()

  const handleReady = () => apiListAssessmentAssignment.get(`${assessmentID}/assessment`)

  const handleDelete = data => apiListAssessmentAssignment.delete(data.id)

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd to={`${url}/add`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="Assignments">
        <CardFullHeightScrollY title="set assignment" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAssessmentAssignment} onReady={handleReady}>
            <ColumnText field="stepTitle" />
            <ColumnText field="objectiveTitle" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
