import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '../../../../context/auth.context'
import { ButtonSecondary } from '../../../../shared/legacy/button'
import { useProviderSignin } from './hooks'
import { useBuildRoutePath } from '../../../../page/hook'
import { useLoad } from '../../../../shared/hooks/load'
import { PositionCenter, TitleMain, TitleSub } from './component'
import { useQueryParam } from '../../../../shared/hooks/location'

export const LoginProviders = () => {
  const history = useHistory()

  const [showProviders, updateShowProviders] = useState(false)

  const authContext = useAuthContext()

  const providerSignin = useProviderSignin()

  let email = useQueryParam('email')
  let redirect = useQueryParam('redirect')
  let redirectDoc = useQueryParam('redirect-doc')

  const buildRoutePath = useBuildRoutePath()

  useLoad(
    async () => {
      if (!email) {
        let path = buildRoutePath('/auth/email', { redirect, redirectDoc })
        history.push(path)
        return
      }

      const url = email.split('@')[1]

      if (!url) {
        let path = buildRoutePath('/auth/password', { email, redirect, redirectDoc })
        history.push(path)
        return
      }

      const result = await authContext.actions.getProvidersUrl(url)

      if (result.length === 0) {
        let loginExists = await authContext.actions.loginExists(email)
        if (loginExists) {
          let path = buildRoutePath('/auth/password', { email, redirect, redirectDoc })
          history.push(path)
        } else {
          let path = buildRoutePath('/public/register', { email, redirect, redirectDoc })
          history.push(path)
        }
      } else if (result.length === 1) {
        updateShowProviders(false)
        providerSignin(result[0], email, redirect, redirectDoc)
      } else {
        updateShowProviders(true)
      }
    },
    [email]
  )

  const handleClick = providerLink => providerSignin(providerLink, email, redirectDoc)

  const handleCancel = () => history.push(`/auth/email?email=${email}`)

  return (
    <React.Fragment>
      <PositionCenter>
        <TitleMain value="Login" />
        <TitleSub value="Sign in to your account" />

        <div className="p-1">
          <input className="form-control" value={email} readOnly />
        </div>

        {showProviders &&
          <div className="pt-3">
            <TitleSub value="Providers" />

            {authContext.state.providerLinkListState.items.map((providerLink, i) => <Provider key={i} providerLink={providerLink} onClick={handleClick} />)}
          </div>}

        <div className="pt-3">
          <ButtonSecondary text="back" onClick={handleCancel} />
        </div>
      </PositionCenter>
    </React.Fragment>
  )
}

const Provider = ({ providerLink, onClick }) => {
  const handleClick = () => onClick && onClick(providerLink)

  return (
    <div className="input-group mb-2 edxr-pointer" onClick={handleClick}>
      <span className="input-group-text">
        {providerLink && <SVG iconSVG={providerLink.iconSVG} />}
      </span>
      <div className="form-control">
        {providerLink && providerLink.displayName}
      </div>
    </div>
  )
}

const SVG = ({ iconSVG }) => {
  const ref = useRef(null)
  useLoad(() => (ref.current.innerHTML = iconSVG), [iconSVG])
  return <div ref={ref} style={{ width: '15px', height: '25px' }} />
}
