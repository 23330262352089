import React from 'react'
import { Route } from 'react-router-dom'
import { AnalyticConfigure } from '../../../../view/analytics/sys/analytic-configure'

export const LayoutAnalytic = () => {
  return (
    <React.Fragment>
      <Route path="/root/analytic-configure" component={AnalyticConfigure} />
    </React.Fragment>
  )
}
