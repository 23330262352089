import React from 'react'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useHistory } from 'react-router-dom'
import { useApiFormAnalyticType } from '../../../../api-new/state/analytics'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../shared/card/card'
import { Form, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'

export const AnalyticTypeEdit = () => {
  const { id } = useParamsInt()

  const history = useHistory()

  const apiFormAnalyticType = useApiFormAnalyticType()

  const handleMatch = async () => apiFormAnalyticType.get(id)

  const handleSubmit = async data => {
    await apiFormAnalyticType.put(data.id, data)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="Edit" onMatch={handleMatch}>
        <CardFullHeightScrollY title="Edit Analytic Type">
          <Card>
            <Form state={apiFormAnalyticType.form} onSubmit={handleSubmit} onRefresh={handleMatch}>
              <FormText label="name" name="name" autoFocus />
              <FormTextArea label="description" name="description" />
              <FormSubmitCancel onCancel={handleClose} />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
