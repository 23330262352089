import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useApiFormAnalyticParent } from '../../../../api-new/state/analytics'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { IconList } from '../../../../shared/icon/icon'
import { Card } from '../../../../shared/card/card'
import { Form, FormSubmitCancel } from '../../../../shared/form/form'
import { FormTextConfirmPurge } from '../../../../shared/form/form-text-confirm-purge'

export const AnalyticParentPurge = () => {
  const { id } = useParamsInt()

  const history = useHistory()

  const apiFormAnalyticParent = useApiFormAnalyticParent()

  const handleMatch = () => apiFormAnalyticParent.get(`${id}/deleted`)

  const handleSubmit = async data => {
    await apiFormAnalyticParent.delete(`${data.id}/purge`)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  const showWarning = useMemo(() => !!apiFormAnalyticParent.form.analyticTopicCount, [apiFormAnalyticParent])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Purge" onMatch={handleMatch}>
        <CardFullHeightScrollY title={`Purge ${apiFormAnalyticParent.form.name}`}>
          {showWarning &&
            <Card>
              <span className="text-danger">
                This parent type has {apiFormAnalyticParent.form.analyticTopicCount} analytic topics attached to it. Purging will remove these and associated
                data.
              </span>
            </Card>}
          <Form state={apiFormAnalyticParent.form} onSubmit={handleSubmit} onRefresh={handleMatch}>
            <Card>
              <FormTextConfirmPurge autoFocus />
              <FormSubmitCancel onCancel={handleClose} />
            </Card>
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
