import React from 'react'
import { useHistory } from 'react-router-dom'
import { useApiFormAnalyticParentType } from '../../../../api-new/state/analytics'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { IconAdd } from '../../../../shared/icon/icon'
import { Card } from '../../../../shared/card/card'
import { Form, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'

export const AnalyticParentTypeAdd = () => {
  const history = useHistory()

  const apiFormAnalyticParentType = useApiFormAnalyticParentType()

  const handleSubmit = async data => {
    await apiFormAnalyticParentType.post(data)
    history.goBack()
  }

  const handleClose = () => history.goBack()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="Add">
        <CardFullHeightScrollY title="Add Parent Type">
          <Card>
            <Form state={apiFormAnalyticParentType.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" autoFocus />
              <FormTextArea label="description" name="description" />
              <FormSubmitCancel onCancel={handleClose} />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
