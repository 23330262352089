import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconSetup } from '../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { AnalyticCard } from '../../common/analytic-card'

export const CourseAnalyticConfigure = () => {
  const { url } = useRouteMatch()
  const history = useHistory()

  const handleTopics = () => history.push(`${url}/topic`)
  const handleAnalytics = () => history.push(`${url}/analytic`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconSetup} text="Custom Analytic Configure">
        <CardFullHeightScrollY title="Custom Analytic Configure">
          <div className="container p-2">
            <div className="row g-2">
              <div className="col-12 col-lg-6">
                <AnalyticCard name="Topics" description="Edit all topics for this course" onClick={handleTopics} />
              </div>
              <div className="col-12 col-lg-6">
                <AnalyticCard name="Analytics" description="Edit all analytics for this course" onClick={handleAnalytics} />
              </div>
            </div>
          </div>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
