import React from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Menu, MenuAdd } from '../../../../../shared/menu/menu'
import { IconOrganization } from '../../../../../shared/icon/icon'
import { useApiListOrganization } from '../../../../../api-new/state/content'
import { useApiListProviderOrganinization } from '../../../../../api-new/state/auth'
import { useRoleSysProvider } from '../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'

export function ProviderOrganizationAdd() {
  const { providerID } = useParamsInt()

  const apiListOrganization = useApiListOrganization()
  const apiListProviderOrganinization = useApiListProviderOrganinization()

  const roleSysProvider = useRoleSysProvider()

  const handleReady = () => {
    apiListOrganization.get()
    apiListProviderOrganinization.get(`${providerID}/provider`)
  }

  const handleAdd = organization => apiListProviderOrganinization.post({ providerID: providerID, organizationID: organization.id })

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconOrganization} text="organization">
        <CardFullHeightScrollY title="add login to organization">
          <TableAgClient apiList={apiListOrganization} onReady={handleReady}>
            <ColumnText field="name" header="Organization" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuAdd text="add" onClick={() => handleAdd(row)} show={roleSysProvider.accessUpdate} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
