import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListProvider } from '../../../../../api-new/state/auth'
import { useRoleSysProvider } from '../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText } from '../../../../../shared/table-builder/table-columns'

export function ProviderRecycle() {
  const apiListProvider = useApiListProvider()

  const roleSysProvider = useRoleSysProvider()

  const handleReady = async () => await apiListProvider.get('deleted')

  const handleRestore = async item => {
    await apiListProvider.put(`${item.id}/restore`)
    await apiListProvider.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableAgClient apiList={apiListProvider} onReady={handleReady}>
            <ColumnText field="providerName" />
            <ColumnText field="displayName" />
            <ColumnText field="url" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={roleSysProvider.accessDelete} />
                  <MenuPurge to={`/admin/provider/list/recycle/purge/${row.id}`} show={roleSysProvider.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
