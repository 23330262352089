import React from 'react'
import { useApiListNodeUser } from '../../../../../../api-new/state/content'
import { Menu, MenuAdd, MenuDelete } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconUser } from '../../../../../../shared/icon/icon'
import { useModalActionID } from '../../../../../../shared/component/modal-dialog'
import { PermissionNodeUserForNodeModal } from '../../../../../../shared/permission-modal/permission-node-user-for-node-modal'
import { useRoleOrgNodeUser } from '../../../../../../role/hook/role-org'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnText, ColumnMenu } from '../../../../../../shared/table-builder/table-columns'

export const PermissionNodeUser = ({ nodeID }) => {
  const apiListNodeUser = useApiListNodeUser()

  const roleOrgNodeUser = useRoleOrgNodeUser()

  const [target, show, hide] = useModalActionID()

  const handleReady = () => apiListNodeUser.get(`${nodeID}/node`)

  const handleAddShow = () => show()
  const handleClose = () => hide()

  const handleClosed = async () => apiListNodeUser.get(`${nodeID}/node`)

  const handleDelete = async item => {
    await apiListNodeUser.delete(`${item.nodeID}/${item.userID}`)
    await apiListNodeUser.get(`${nodeID}/node`)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" onClick={handleAddShow} show={roleOrgNodeUser.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <PermissionNodeUserForNodeModal target={target} nodeID={nodeID} onClose={handleClose} onClosed={handleClosed} />
      <BreadcrumbView Icon={IconUser} text="user permissions">
        <CardFullHeightScrollY title="users" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListNodeUser} onReady={handleReady}>
            <ColumnText field="email" header="Email" />
            <ColumnText field="firstName" header="First Name" />
            <ColumnText field="lastName" header="Last Name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show={roleOrgNodeUser.accessDelete} />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
