import React from 'react'
import { Route } from 'react-router-dom'
import { AnalyticOrgGeneral } from '../../../../view/analytics/org/analytic-org-general'
import { AnalyticOrgCustom } from '../../../../view/analytics/org/analytic-org-custom'

export const LayoutAnalytic = () => {
  return (
    <React.Fragment>
      <Route path="/org/analytic/general" component={AnalyticOrgGeneral} />
      <Route path="/org/analytic/custom" component={AnalyticOrgCustom} />
    </React.Fragment>
  )
}
