import { useMemo, useState } from 'react'
import { useApiListNodeOrg, useApiListNodeUser } from '../../api-new/state/content'
import { ModalDialog } from '../component/modal-dialog'
import { TextFilter } from './text-filter'
import { IconMinus, IconPlus } from '../icon/icon'
import { ButtonPrimary } from '../component/button'

export const PermissionNodeUserForUserModal = ({ target, userID, onClose, onClosed }) => {
  const [filter, updateFilter] = useState('')

  const apiListNodeOrg = useApiListNodeOrg()
  const apiListNodeUser = useApiListNodeUser()

  const filteredItems = useMemo(
    () => {
      let lowerFilter = filter.toLowerCase()
      return apiListNodeOrg.items.filter(i => i.name.toLowerCase().includes(lowerFilter))
    },
    [apiListNodeOrg.items, filter]
  )

  const handleOpen = () => {
    apiListNodeOrg.get()
    apiListNodeUser.get(`${userID}/user`)
  }
  const handleClose = () => onClose && onClose()

  const handleAdd = async nodeID => {
    await apiListNodeUser.post({ nodeID: nodeID, userID: userID })
    await apiListNodeUser.get(`${userID}/user`)
  }

  const handleRemove = async nodeID => {
    await apiListNodeUser.delete(`${nodeID}/${userID}`)
    await apiListNodeUser.get(`${userID}/user`)
  }

  return (
    <ModalDialog target={target} header="Add Content" fullScreen onOpen={handleOpen} onClose={onClosed}>
      <div className="d-flex flex-column h-100">
        <div>
          <TextFilter value={filter} onChange={updateFilter} />
        </div>
        <div className="flex-fill overflow-auto p-2">
          {filteredItems.map((node, i) =>
            <SelectItem key={i} node={node} selectedNodeUsers={apiListNodeUser.items} onAdd={handleAdd} onRemove={handleRemove} />
          )}
        </div>
        <div>
          <ButtonPrimary onClick={handleClose}>close</ButtonPrimary>
        </div>
      </div>
    </ModalDialog>
  )
}

const SelectItem = ({ node, selectedNodeUsers, onAdd, onRemove }) => {
  const isSelected = useMemo(
    () => {
      return selectedNodeUsers.findIndex(selectedNodeUser => selectedNodeUser.nodeID === node.id) !== -1
    },
    [selectedNodeUsers, node]
  )

  const handleClick = () => {
    if (isSelected) {
      onRemove && onRemove(node.id)
    } else {
      onAdd && onAdd(node.id)
    }
  }

  return (
    <div className="p-1 border-bottom" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <span className="px-1">
        {isSelected ? <IconMinus /> : <IconPlus />}
      </span>
      <span>
        {node.name}
      </span>
    </div>
  )
}
