import { useHistory, useRouteMatch } from 'react-router-dom'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useLoad } from '../../../../shared/hooks/load'
import { useApiListAnalyticTopic } from '../../../../api-new/state/analytics'
import { AnalyticCard } from '../../common/analytic-card'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { IconGrid } from '../../../../shared/icon/icon'

export const AnalyticGridTopic = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const apiListAnalyticTopic = useApiListAnalyticTopic()

  useLoad(() => apiListAnalyticTopic.get(`${nodeID}/node`), [nodeID])

  const handleClickAll = () => history.push(`${url}/all`)
  const handleClick = id => history.push(`${url}/${id}/analytic`)

  return (
    <BreadcrumbView text="Custom Analytic Topics" Icon={IconGrid}>
      <CardFullHeightScrollY title="Custom Analytic Topics">
        <div className="container p-2">
          <div className="row g-2">
            <div className="col-12 col-lg-4">
              <AnalyticCard name="all" description="Course analytics for all topics" onClick={handleClickAll} />
            </div>
            {apiListAnalyticTopic.items.map((topic, i) =>
              <div key={i} className="col-12 col-lg-4">
                <AnalyticCard id={topic.id} name={topic.name} description={topic.description} onClick={handleClick} />
              </div>
            )}
          </div>
        </div>
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
