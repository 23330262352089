import React, { useEffect, useMemo, useState } from 'react'
import { FormatPrecent } from '../../../../../shared/legacy/format'
import * as moment from 'moment'

const aggergateAverageCompletion = (sessionID, deviceSteps) => {
  if (!deviceSteps || deviceSteps.length === 0) {
    return 0
  }

  if (sessionID && sessionID !== 'all') {
    deviceSteps = deviceSteps.filter(step => step.sessionID === parseInt(sessionID))
  }

  let objectives = 0
  let objectivesCompleted = 0

  for (let step of deviceSteps) {
    objectives += step.objectives ? step.objectives : 0
    objectivesCompleted += step.objectivesCompleted ? step.objectivesCompleted : 0
  }

  if (objectives) return objectivesCompleted / objectives
  else return 0
}

const aggergateAverageDuration = (sessionID, deviceSteps) => {
  if (!deviceSteps || deviceSteps.length === 0) {
    return 0
  }

  if (sessionID && sessionID !== 'all') {
    deviceSteps = deviceSteps.filter(step => step.sessionID === parseInt(sessionID))
  }

  let durationSum = 0

  for (let step of deviceSteps) {
    durationSum += step.duration
  }

  let aveDuration = durationSum / deviceSteps.length
  return moment.utc(moment.duration(aveDuration).as('millisecond')).format('mm:ss')
}

const Item = ({ number, text }) => {
  return (
    <div className="d-flex bg-primary text-white border rounded p-2">
      <div className="flex-fill text-end px-1">
        {number}
      </div>
      <div className="flex-fill px-1">
        {text}
      </div>
    </div>
  )
}

export default function Component({ sessionID, details, deviceSteps }) {
  const [averageCompletion, updateAverageCompletion] = useState(0)
  const [averageDuration, updateAverageDuration] = useState(0)

  const hasSession = useMemo(() => !(sessionID === null || sessionID === 'all'), [sessionID])

  useEffect(
    () => {
      updateAverageCompletion(aggergateAverageCompletion(sessionID, deviceSteps))
    },
    [sessionID, deviceSteps]
  )

  useEffect(
    () => {
      updateAverageDuration(aggergateAverageDuration(sessionID, deviceSteps))
    },
    [sessionID, deviceSteps]
  )

  return (
    <React.Fragment>
      <div className="row no-gutters m-2">
        <div className="col-4 px-1">
          <Item number={details.userCount} text="Authorized Users" />
        </div>
        <div className="col-4 px-1">
          <Item number={<FormatPrecent value={averageCompletion} />} text="Average Completion" />
        </div>
        <div className="col-4 px-1">
          <Item number={averageDuration} text={hasSession ? 'Average Step Time' : 'Average Session Time'} />
        </div>
      </div>
    </React.Fragment>
  )
}
