import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Menu, MenuActivityLog, MenuAdd, MenuEdit, MenuItem } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useApiListAppVersion } from '../../../../../../api-new/state/config'
import { IconDownload, IconList, IconRecycle, IconDelete } from '../../../../../../shared/icon/icon'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'
import { TableAgClient } from '../../../../../../shared/table-builder/table-ag-client'
import { ColumnMenu, ColumnText, ColumnYesNo } from '../../../../../../shared/table-builder/table-columns'

export const AppVersionList = () => {
  const { appID, appStoreID } = useParamsInt()

  const apiListAppVersion = useApiListAppVersion()

  const roleSysApp = useRoleSysApp()

  const handleReady = () => apiListAppVersion.get(`${appStoreID}/store`)

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/add`} show={roleSysApp.accessCreate} />
      <MenuItem Icon={IconDownload} text="downloads" to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/downloads`} show={roleSysApp.accessRead} />
      <MenuActivityLog to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/activity-all`} show />
      <MenuItem Icon={IconRecycle} text="recycle" to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/recycle`} show={roleSysApp.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="versions">
        <CardFullHeightScrollY title="app store versions" HeaderMenu={HeaderMenu}>
          <TableAgClient apiList={apiListAppVersion} onReady={handleReady}>
            <ColumnText field="name" />
            <ColumnText field="code" />
            <ColumnYesNo field="isRequired" header="Required" />
            <ColumnYesNo field="isActive" header="Active" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem
                    Icon={IconDelete}
                    text="delete"
                    to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/edit/${row.id}/delete`}
                    show={roleSysApp.accessDelete}
                  />
                  <MenuEdit to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/edit/${row.id}`} show={roleSysApp.accessUpdate} />
                  <MenuActivityLog to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableAgClient>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
